import React from 'react'

import { Container } from 'bootstrap-4-react'
import { ContactUsForm } from 'components/contact-form'

const ContactUsPage = ()=>(
    <Container>
    <ContactUsForm />
    </Container>
)

export default ContactUsPage;