import React, { useState, useEffect } from 'react';



import { db } from 'util/Firebase/firebase';
import cf from 'util/caravan-functions';
import _ from 'lodash';

import ApiError from '_errors/ApiError';

import { fbSingleGet, fbListGet } from '_common/util/firebase-util'
import serviceFactory from 'services/ServiceFactory';
import { Button } from 'bootstrap-4-react'

import CaravanSummary from 'components/caravan/caravan-summary';
import CaravanSummaryList from 'components/caravan/caravan-list';
import CaravanDetail from 'components/caravan/caravan-detail';
// import data_caravans from './caravan-data'

const caravanService = serviceFactory.getCaravanService();


const TestPage = () => {
  console.log("test page");
  const [result, setResult] = useState();
  const [caravanDetail, setCaravanDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             setLoading(false);
  //             const caravans = await caravanService.getCaravanSummaries();
  //             setCaravans(caravans);
  //             setLoading(false);
  //             console.log(caravans);
  //         } catch (err) {
  //             setLoading(false);
  //             setError(err);
  //         }
  //     };
  //     fetchData();
  // }, []);
  const now = Date();

  // const createPhotoOrdering = () => {
  //     data_caravans.caravans.map((caravan) => {


  //         if (caravan.photos && caravan.photos.length > 0) {
  //             let dateStr = caravan.lastLogon ? caravan.lastLogon : caravan.lastSent ? caravan.lastSent : null;
  //             let date;
  //             if (dateStr)
  //                 date = new Date(dateStr)
  //             else
  //                 date = now;

  //             console.log(caravan.lastLogon + " " + caravan.lastSent + " " + date + " " + date.getTime());


  //             let photoOrderingList = [date.getTime(), ...caravan.photos.split()];
  //             const photoOrdering = photoOrderingList.join();
  //             return db.collection("caravans").doc(caravan.uid).update({
  //                 photoOrdering

  //             })
  //                 .catch(error => {
  //                     throw (new ApiError("MessageService createCaravan", error,
  //                         caravan));
  //                 });
  //         }

  //     })
  // }


  // const createLastLogin =()=>{
  //     data_caravans.caravans.map((caravan)=>{


  //             let dateStr = caravan.lastLogon? caravan.lastLogon: caravan.lastSent? caravan.lastSent:caravan.joinDate;
  //             let lastLogon = new Date(dateStr)
  //             console.log(caravan.uid+" "+lastLogon);
  //             return db.collection("caravans").doc(caravan.uid).update({              
  //                 lastLogon: cf.formatDate(lastLogon)

  //           })
  //             .catch(error => {
  //               throw (new ApiError("MessageService createCaravan", error,
  //               caravan));
  //             });


  //     })
  // }

  //     const doDates =()=>{

  //         let i=1;
  //         data_caravans.caravans.map((caravan)=>{

  // // if (i==3) return; i++;
  //                 let lastLogonOrigStr = caravan.lastLogon? caravan.lastLogon: caravan.lastSent? caravan.lastSent:caravan.joinDate;
  //                 let lastLogonDate = new Date(lastLogonOrigStr)

  //                 let lastSentOrigStr = caravan.lastSent;
  //                 let lastReceivedOrigStr = caravan.lastReceived;
  //                 let lastReadOrigStr = caravan.lastReceived;
  //                 let joinDateOrigStr= caravan.joinDate;

  //                 let details ={
  //                     lastLogonOrigStr, lastSentOrigStr,lastReceivedOrigStr,lastReadOrigStr,joinDateOrigStr,
  //                     lastLogon: cf.formatDate(new Date(lastLogonOrigStr)),
  //                     lastLogonTime: lastLogonDate.getTime(),
  //                     lastSent: cf.formatDate(new Date(lastSentOrigStr)),
  //                     lastReceived: cf.formatDate(new Date(lastReceivedOrigStr)),
  //                     lastRead: cf.formatDate(new Date(lastReadOrigStr)),
  //                     joinDate:cf.formatDate(new Date(joinDateOrigStr))

  //                 }
  //                 console.log(JSON.stringify(details));

  //                 return db.collection("caravans").doc(caravan.uid).update({              
  //                 lastLogon: cf.formatDate(new Date(lastLogonOrigStr)),
  //                 lastLogonTime: lastLogonDate.getTime(),
  //                 lastSent: cf.formatDate(new Date(lastSentOrigStr)),
  //                 lastReceived: cf.formatDate(new Date(lastReceivedOrigStr)),
  //                 lastRead: cf.formatDate(new Date(lastReadOrigStr)),
  //                 joinDate:cf.formatDate(new Date(joinDateOrigStr))

  //               })
  //                 .catch(error => {
  //                   throw (new ApiError("MessageService createCaravan", error,
  //                   caravan));
  //                 });


  //         })

  //         console.log(data_caravans.caravans.length);
  //     }


  // const doJoinDate =()=>{

  //     let i=1;
  //     data_caravans.caravans.map((caravan)=>{

  // // if (i==3) return; i++;
  //             let lastLogonOrigStr = caravan.lastLogon? caravan.lastLogon: caravan.lastSent? caravan.lastSent:caravan.joinDate;


  //             console.log(cf.formatDate(new Date(lastLogonOrigStr)));

  //             return db.collection("caravans").doc(caravan.uid).update({              
  //             joinDate:cf.formatDate(new Date(lastLogonOrigStr))

  //           })
  //             .catch(error => {
  //               throw (new ApiError("MessageService createCaravan", error,
  //               caravan));
  //             });


  //     })

  //     console.log(data_caravans.caravans.length);
  // }

  // const doJoinDate =()=>{

  //     let i=1;
  //     data_caravans.caravans.map((caravan)=>{

  // //  if (i==3) return; i++;

  //             return db.collection("caravans").doc(caravan.uid).update({              
  //             joinDate:null

  //           })
  //             .catch(error => {
  //               throw (new ApiError("MessageService createCaravan", error,
  //               caravan));
  //             });


  //     })

  //     console.log(data_caravans.caravans.length);
  // }


  // const getCaravans = () => {
  //   let caravanDetails = new Map();
  //   let caravans = [];

  //   let loadDescription = (caravan) => {
  //     return db.collection("caravans").doc(caravan.uid).collection("details").doc("detail").get()
  //       .then(function (doc) {
  //         caravan.description = doc.data().description;
  //       })
  //   }



  //   return db.collection("caravans")
  //     // .limit(2)
  //     .get()
  //     .then(function (querySnapshot) {
  //       return querySnapshot.forEach(function (doc) {
  //         caravans.push({ uid: doc.id, ...doc.data() });
  //       });
  //     })
  //     .then(() => {
  //       const promises = [];
  //       caravans.forEach((caravan) => {
  //         promises.push(loadDescription(caravan));
  //       })
  //       return Promise.all(promises)
  //     })
  //     .then(() => {
  //       setResult(JSON.stringify(caravans));
  //       console.log("******* "+caravans.length)
  //     })
  //     .then(() => {

  //     })
  // }

  



  // const doCounty = () => {

  //   let batch = db.batch();

  //   const changeCounty = (countyFrom, countyTo) => {
  //     return db.collection("caravans").where("county", "==", countyFrom).get()
  //       .then(function (querySnapshot) {
  //         querySnapshot.forEach(function (doc) {
  //           batch.update(doc.ref, { county: countyTo });
  //         });
  //       })
  //   }



  //     changeCounty("Durham Dales","Durham")            
  //     .then(() => {
  //       return changeCounty("dorset","Dorset")        
  //     })
  //     .then(() => {
  //       return changeCounty("North Lancashire","Lancashire")        
  //     })
  //     .then(() => {
  //       return changeCounty("North Yorkshire","Yorkshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("northumberland","Northumberland")        
  //     })
  //     .then(() => {
  //       return changeCounty("East Sussex","Sussex")        
  //     })
  //     .then(() => {
  //       return changeCounty("essex","Essex")        
  //     })
  //     .then(() => {
  //       return changeCounty("North Devon","Devon")        
  //     })
  //     .then(() => {
  //       return changeCounty("Yorkshire Malton & Pickering","Yorkshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("BRECON","Brecon")        
  //     })
  //     .then(() => {
  //       return changeCounty("yorkshire","Yorkshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("cumbria","Cumbria")        
  //     })
  //     .then(() => {
  //       return changeCounty("West Sussex","Sussex")        
  //     })
  //     .then(() => {
  //       return changeCounty("Devon ","Devon")        
  //     })
  //     .then(() => {
  //       return changeCounty("east sussex","Sussex")        
  //     })
  //     .then(() => {
  //       return changeCounty("Burnham On Sea","Somerset")        
  //     })
  //     .then(() => {
  //       return changeCounty("cornwall","Cornwall")        
  //     })
  //     .then(() => {
  //       return changeCounty("arbroath","Angus")        
  //     })
  //     .then(() => {
  //       return changeCounty("norfolk /hunstanton","Norfolk")        
  //     })
  //     .then(() => {
  //       return changeCounty("new quay","Ceredigion")        
  //     })
  //     .then(() => {
  //       return changeCounty("east yorkshire","Yorkshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("Eastyorkshire","Yorkshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("East Yorkshire","Yorkshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("Litton Dale, Yorkshire Dales","Yorkshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("West Yorkshire","Yorkshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("Blackpool lancashire","Lancashire")        
  //     })
  //     .then(() => {
  //       return changeCounty("Norfolk Coast","Norfolk")        
  //     })
  //     .then(() => {
  //       return changeCounty("Cumbria /Northumberland","Northumberland")        
  //     })
  //     .then(() => {
  //       return changeCounty("cumbria","Cumbria")        
  //     })
  //     .then(() => {
  //       return changeCounty("Lincs","Lincolnshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("carmarthenshire","Carmarthenshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("ceredigon","Ceredigion")        
  //     })
  //     .then(() => {
  //       return changeCounty("Ayshire","Ayrshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("Nr Blackpool","Lancashire")        
  //     })
  //     .then(() => {
  //       return changeCounty("kent","Kent")        
  //     })
  //     .then(() => {
  //       return changeCounty("Ayr","Ayrshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("Cornwall, North.","Cornwall")        
  //     })
  //     .then(() => {
  //       return changeCounty("fife","Fife")        
  //     })
  //     .then(() => {
  //       return changeCounty("isle of wight","Isle of Wight")        
  //     })
  //     .then(() => {
  //       return changeCounty("Dumfries and Galloway.","Dumfriesshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("Whitley bay ","Northumberland")        
  //     })
  //     .then(() => {
  //       return changeCounty("burnham on sea/brean","Somerset")        
  //     })
  //     .then(() => {
  //       return changeCounty("NEAR RHYL","Flintshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("pagham","Sussex")        
  //     })
  //     .then(() => {
  //       return changeCounty("Mablethorpe","Dumfriesshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("scottish borders","Dumfriesshire")        
  //     })
  //     .then(() => {
  //       return changeCounty("West Cork","Cork")        
  //     })
  //     .then(() => {
  //       return changeCounty("enniskillen","Fermanagh")        
  //     })
  //     .then(() => {
  //       return changeCounty("West Wales","Ceredigion")        
  //     })
  //     .then(() => {
  //       return changeCounty("arbroath","Angus")        
  //     })
  //     .then(() => {
  //       return changeCounty("Ayr","Ayrshire")        
  //     })
  //     .then(() => {
  //       return batch.commit().then(function () {
  //         console.log("COMPLETE");
  //       });
  //     })

  // }

  // const doCountry = () => {

  //   let batch = db.batch();





  //   db.collection("caravans")
  //     .where("country", "==", "England ")
  //     .get()
  //     .then(function (querySnapshot) {
  //       querySnapshot.forEach(function (doc) {
  //         batch.update(doc.ref, { country: "England" });
  //       });
  //     })


  //     .then(() => {
  //       return db.collection("caravans")
  //         .where("country", "==", "France")
  //         .get()

  //     })
  //     .then(function (querySnapshot) {
  //       querySnapshot.forEach(function (doc) {
  //         batch.update(doc.ref, { country: "Other" });
  //       });
  //     })



  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","United Kingdom")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })

  // .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","england")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })


  // .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","uk")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })

  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","UK")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })




  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","WALES")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "Wales"});        
  //     });      
  //   })


  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","ENGLAND")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })



  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","united kingdom")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })


  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","spain")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "Other"});        
  //     });      
  //   })



  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","Somerset")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })


  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","scotland")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "Scotland"});        
  //     });      
  //   })



  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","west wales")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "Wales"});        
  //     });      
  //   })



  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","Lanzarote")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "Other"});        
  //     });      
  //   })


  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","Suffolk")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })



  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","UK Mainland")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })



  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","wales")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "Wales"});        
  //     });      
  //   })


  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","Uk")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })


  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","sussex")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })



  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","Mablethorpe England")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })








  //   .then(()=>{
  //     return db.collection("caravans")
  //     .where("country","==","Suffolk")
  //     .get()

  // })
  // .then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {        
  //       batch.update(doc.ref, {country: "England"});        
  //     });      
  //   })







  //     .then(() => {
  //       return batch.commit().then(function () {
  //         console.log("COMPLETE");
  //       });
  //     })


  //   console.log(data_caravans.caravans.length);
  // }


  // const doStatus =()=>{

  //     let i=1;
  //     data_caravans.caravans.map((caravan)=>{

  // //  if (i==3) return; i++;

  //             return db.collection("caravans").doc(caravan.uid).update({              
  //             status:""

  //           })
  //             .catch(error => {
  //               throw (new ApiError("MessageService createCaravan", error,
  //               caravan));
  //             });


  // //     })

  // //     console.log(data_caravans.caravans.length);
  // // }

  const loadCaravanData = () => {
    // console.log(data_caravans.length);
    // return;
      // data_caravans.map((caravan) => {

      
      //     return db.collection("caravans").doc(caravan.uid).set({
      //         country: caravan.country,
      //         county: caravan.county,
      //         town: caravan.town,
      //         heading: caravan.heading,
      //         shortDescription: caravan.shortDescription,
      //         available: caravan.available,
      //         availableMonths: caravan.availableMonths,
      //         type: caravan.type,
      //         bedrooms: caravan.bedrooms,
      //         sleeps: caravan.sleeps,
      //         whatsOk: caravan.whatsOk,
      //         period : caravan.period?caravan.period:[],
      //         inside: caravan.inside,
      //         outside: caravan.outside,
      //         photos: caravan.photos?caravan.photos:'',              
      //         shortDescription: caravan.shortDescription,
      //         photoOrdering: caravan.photoOrdering?caravan.photoOrdering:0,
      //         lastLogon: caravan.lastLogon,              
      //         lastRead: caravan.lastLogon,
      //         nsent: caravan.nsent?caravan.nsent:0,
      //         nreceived: caravan.nreceived?caravan.nreceived:0,
      //         lastSent: caravan.lastSent?caravan.lastSent:caravan.lastLogon,
      //         lastReceived: caravan.lastReceived?caravan.lastReceived:caravan.lastLogon,
      //         joinDate: caravan.joinDate,
      //         lastLogonTime: caravan.lastLogonTime,
      //         status:""
              

      //     })



      //         .then(() => {
      //             return db.collection("caravans").doc(caravan.uid).collection("details").doc("detail").set({
      //                 description: caravan.description
      //             })

      //         })
      //         .catch(error => {
      //             throw (new ApiError("MessageService createCaravan", error,
      //                 caravan));
      //         });


      // })
  }


  const doStatus = () => {

    // let batch = db.batch();

    // const changeStatus= () => {
    //   return db.collection("caravans").get()
    //     .then(function (querySnapshot) {
    //       querySnapshot.forEach(function (doc) {
    //         batch.update(doc.ref, { status: "" });
    //       });
    //     })
    // }

    // changeStatus()            
    // .then(() => {
    //         return batch.commit().then(function () {
    //           console.log("COMPLETE");
    //           setResult("COMPLETE");
    //         });
    //       })
}

const doTest = () => {

  
  let count=0;
    return db.collection("caravans").get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          count++
        });
      })
      .then(()=>setResult(count))

}

  return (<>



    <h1>Working: {"" + loading} </h1>
    <Button primary onClick={() => doTest()}>doTest</Button>
    <textarea style={{ width: '80%', height: '400px' }} value={result}></textarea>
  </>
  )
}

export default TestPage;