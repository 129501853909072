import { auth, db } from "util/Firebase/firebase";
//import Cookies from 'universal-cookie';

import passwordHash from "password-hash";

import isBefore from "date-fns/isBefore";

import EmailVerificationError from "../util/email-verification-error";

import { c_error, c_log } from "_common/util/logger";
// import { BehaviorSubject} from 'rxjs/internal/BehaviorSubject'
import { Subject } from "rxjs/internal/Subject";
import ma from "_common/util/missingArg";
import CopyUserError from "_registration/util/copy-user-error";
import DeleteOldDisplayeNameError from "_registration/util/delete-old-display-name-error";
import {REG_TYPE} from 'constants/index'

// const USER_COPY_TABLE = "user_copy"
const DISPLAY_NAME_UNIQUE_TABLE = "display_name";
const USER_PROFILE_TABLE = "user_profile";
const USER_EVENT_TABLE = "user_event";

// const userSubject = new BehaviorSubject();
const userSubject = new Subject();

//const cookies = new Cookies();

const canLogin = (sessionUser) => {};

const subscribeUserChange = (cb) => userSubject.asObservable().subscribe(cb);

let unsubscribe = null;

auth.onAuthStateChanged(function (user) {
  
  // taken from https://firebase.google.com/docs/auth/admin/custom-claims
  // Remove previous listener.
  if (unsubscribe) {
    unsubscribe();
  }
  // On user login add new listener.
  if (user) {
    // Check if refresh is required.
    unsubscribe = db
      .collection("metadata")
      .doc(user.uid)
      .onSnapshot((doc) => {
        user.getIdToken(true);
      });
  }

  _buildSessionUserFromFBUser(user).then((sessionUser) => {
    // if (
    //   sessionUser === null ||
    //   ((!REG_EMAIL_VERIFICATION_REQUIRED || sessionUser.emailVerified) && sessionUser.approved)
    // ){
    _sessionUserChanged(sessionUser);
    // }
  });
});

const _sessionUserChanged = (sessionUser) => {
  
  userSubject.next(sessionUser);
};

const verifyDisplayNameUnique = (displayName = ma()) => {
  return db
    .collection(DISPLAY_NAME_UNIQUE_TABLE)
    .doc(displayName)
    .get()
    .then((doc) => {
      if (doc.exists) {
        let error = new Error("Display name is already in use");
        error.name = "auth/name-already-in-use";
        return Promise.reject(error);
      }
    })
    .then(() => {})
    .catch((error) => {
      throw error;
    });
};

//Promise<uid>
const registerWithEmailAndPassword = async ({
  email = ma(),
  password = ma(),
  type = "A",
}) => {
  try {
    const userCredential = await auth.createUserWithEmailAndPassword(
      email,
      password
    );

    await db.collection(USER_EVENT_TABLE).doc(userCredential.user.uid).set({
      event: "REG_TYPE",
      value: type,
    });
    return userCredential.user.uid;
  } catch (error) {
    throw error;
  }
};

//Promise<void>
const sendEmailVerification = (confirmedEmailSuccessUrl = ma()) => {
  return auth.currentUser
    .sendEmailVerification({
      url: confirmedEmailSuccessUrl,
    })
    .catch((error) => {
      throw new EmailVerificationError(error);
    });
};

//Promise<user>
const loginWithEmailAndPassword = async ({ email = ma(), password = ma() }) => {
  return (
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        // cookies.set("username", user.displayName, { path: "/" });
        // cookies.set("avatar", user.photoURL, { path: "/" });
        return _buildSessionUserFromFBUser(user);
      })
      // .then((appUser)=>{
      //   if (appUser.emailVerified && appUser.approved){
      //     _sessionUserChanged(appUser);
      //   }
      //   return appUser;
      // })
      .catch((error) => {
        throw error;
      })
  );
};

//Promise<void>
const logout = () => {
  return auth.signOut().then(() => {
    // cookies.remove("username");
    // cookies.remove("avatar");
    
    _sessionUserChanged(null);
  });
};

// const updateApproved =()=>{
//   const user = auth.currentUser;
//   const photoUrl = user.photoURL.replace(".NA","");
//   return user.updateProfile({
//     photoURL: photoUrl
//   })
// }

const createAuthProfile = ({
  password = ma(),
  email = ma(),
  displayName = ma(),
  regType = ma(),
}) => {
  const user = auth.currentUser;

  return user.updateProfile({
    displayName,
    photoURL: "https://via.placeholder.com/1.jpg",
  });
};

const updateDisplayName = (displayName = ma()) => {
  const authUser = auth.currentUser;
  const oldDisplayName = authUser.displayName;
  return (
    createDisplayNameUniqueness(displayName)
      .then(() => {
        return authUser.updateProfile({
          displayName,
        });
      })
      // .then(() => { DONt THINK NEEd TO DO THIS NOW. HAVENT TEST THOUGH
      //   return _b uildSessionUserFromFBUser(authUser);
      // })
      // .then((sessionUser) => {
      
      //   return _s essionUserChanged(sessionUser);
      // })
      .then(() => {
        return _deleteDisplayNameUniqueness(oldDisplayName);
      })
      // .then(() => {
      //   _updateCopyOfUser({ displayName })
      // })
      .catch((error) => {
        if (
          !(error instanceof CopyUserError) &&
          !(error instanceof DeleteOldDisplayeNameError)
        ) {
          throw error;
        } else {
          c_error(error);
        }
      })
  );
};

const exampleCreateFullProfile = (uid, name, about, gender, age, sports) => {
  return db
    .collection(USER_PROFILE_TABLE)
    .doc(uid)
    .set({
      name,
      gender,
      age,
      sports,
      about,
    })
    .catch((error) => {
      throw error;
    });
};

//Promise<void>
const doPasswordReset = (email = ma()) => {
  return auth.sendPasswordResetEmail(email).catch((error) => {
    throw error;
  });
};

//Promise<void>
const updateEmail = (email = ma()) => {
  const authUser = auth.currentUser;

  return (
    authUser
      .updateEmail(email)
     
      // .then(() => { DONt THINK NEED TO DO THIS
      
      //   return _sessionUserChanged(_buildSessionUserFromFBUser(authUser));
      // })

      .catch((error) => {
        if (!(error instanceof CopyUserError)) {
          throw error;
        } else {
          c_error(error);
        }
      })
  );
};

//just messing:
const findUser = (uid = ma()) => {};

//just messing:
const findUsers = () => {};

const _buildSessionUserFromFBUser = (authUser = ma()) => {
  if (!authUser) return Promise.resolve();

  const preClaimsUser = isBefore(
    new Date(authUser.metadata.creationTime),
    new Date(2021, 2, 27)
  );
  return authUser.getIdTokenResult(true).then((idTokenResult) => {
    return {
      uid: authUser.uid,
      email: authUser.email,
      emailVerified: authUser.emailVerified,
      displayName: authUser.displayName,
      type:
        typeof idTokenResult.claims.type != "undefined"
          ? idTokenResult.claims.type
          : REG_TYPE,
      approved: idTokenResult.claims.approved != "undefined"
      ? idTokenResult.claims.approved
      : true,
      photoUrl: authUser.photoURL,
    };
  });
};

// const _parsePhotoUrl = ({ photoURL = "" }) => {
//   let type = "A";
//   if (photoURL===null)
//     photoURL="";
//   else
//     photoURL = photoURL.replace("_|_","?type="); //this corrects the old way it
//   let i = photoURL.indexOf("type=");
//   if (i > -1) {
//     type = photoURL.substring(i + 5, photoURL.length);
//   }
//   return { photoURL, type };
// }

// const _deriveStoredPhotoUrl = ({photoUrl = "", regType = "A"}) => {
//   return photoUrl + "?type=" + regType;
// }

const createDisplayNameUniqueness = (displayName = ma()) => {
  const user = auth.currentUser;
  return db
    .collection(DISPLAY_NAME_UNIQUE_TABLE)
    .doc(displayName)
    .set({
      uid: user.uid,
    })
    .catch((error) => {
      throw error;
    });
};

const _deleteDisplayNameUniqueness = (displayName) => {
  try {
    const user = auth.currentUser;
    return db
      .collection(DISPLAY_NAME_UNIQUE_TABLE)
      .doc(displayName)
      .delete()
      .catch((error) => {
        throw new DeleteOldDisplayeNameError(error);
      });
  } catch (error) {
    throw new DeleteOldDisplayeNameError(error);
  }
};

// const _createCopyOfUser = ({ password = ma(), email = ma(), displayName = ma(), regType = ma() }) => {
//   try {
//     const user = auth.currentUser;

//     return db.collection(USER_COPY_TABLE).doc(user.uid).set(
//       {
//         oid: passwordHash.generate(password),
//         email,
//         displayName,
//         regType
//       }
//     )
//       .catch(error => {
//         throw new CopyUserError(error);
//       })
//   }
//   catch (error) {
//     throw new CopyUserError(error);
//   }
// }

// const _updateCopyOfUser = ({ displayName, email }) => {
//   const authUser = auth.currentUser;
//   try {
//     let propsToUpdate = {}
//     if (displayName)
//       propsToUpdate.displayName = displayName;
//     if (email)
//       propsToUpdate.email = email;

//     return db.collection(USER_COPY_TABLE).doc(authUser.uid).update(
//       propsToUpdate
//     )
//       .catch(error => {
//         throw new CopyUserError(error);
//       })
//   }
//   catch (error) {
//     throw new CopyUserError(error);
//   }

// }

const userService = {
  subscribeUserChange,
  registerWithEmailAndPassword,
  sendEmailVerification,
  loginWithEmailAndPassword,
  logout,
  createAuthProfile,
  findUser,
  findUsers,
  doPasswordReset,
  exampleCreateFullProfile,
  verifyDisplayNameUnique,
  updateDisplayName,
  updateEmail,
  createDisplayNameUniqueness,
  //updateApproved
};

export default userService;

//export { deriveDisplayNameAndTypeFromValue, deriveValueFromDisplayNameAndType }
