import React from 'react'

import { Alert, Container } from 'bootstrap-4-react';
import { useHistory } from "react-router-dom";

import * as ROUTES from 'constants/routes';
import {WarnIcon} from '_icons'
import useCancel from '_hooks/use-cancel'
import { UpdateEmailForm } from '_registration/components/email-address'


const UpdateEmail = (props) => {
    const history = useHistory();
    const cancel = useCancel(ROUTES.ACCOUNT_HOME);

    return (
        <Container>
        <Alert warning> When you change your email we will send you a link to click on. 
            We will also send a link to your previous email address in case you change your mind</Alert>
        <UpdateEmailForm
            success={() => history.replace(ROUTES.PLEASE_CONFIRM_EMAIL)}
            cancel={() => cancel()}
            confirmedEmailSuccessUrl={ROUTES.EMAIL_CONFIRMED_SUCCESS_URL}
        />
</Container>
    )
}



export default UpdateEmail