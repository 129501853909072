import _ from 'lodash';

import messageService from './MessageService';
import caravanService from './CaravanService';




const getUserInbox = () => {
  let messages = {};
  let uids = [];
  return messageService.getUserInbox()
    .then((result) => {
      messages = result;      
      messages.map((message, i) => {
        uids.push(message.fromUID)
      });
      
      return;
    })    
    .then(() => {
      return caravanService.getCaravanSummariesByListOfUID(uids)
      .then((caravans)=>{
        messages.map((message, i) => {
          message.caravan = _.find(caravans,  'uid', message.fromUID)
        });
        return messages;

      })
           
    })    
}

const getUserSent = () => {
  let messages = {};
  return messageService.getUserSent()
    .then((result) => {
      messages = result;
      let uids = [];
      messages.map((message, i) => {
        uids.push(message.toUID)
      });
      
      return uids
    })
    .then((uids) => {
      return caravanService.getCaravanSummariesByListOfUID(uids)
      .then((caravans)=>{ 
        messages.map((message, i) => {
          let c  = _.find(caravans, 'uid', message.toUID);
          
          message.caravan = c;
        });
        return messages;
      })
     
    })
}


const messageWithCaravanService = {
  getUserInbox,
  getUserSent

}

export default messageWithCaravanService;