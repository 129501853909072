import React from 'react';
import ReactDOM from 'react-dom'
import { render } from 'react-snapshot';
import * as Sentry from '@sentry/browser';

import App from './components/App';

if (process.env.NODE_ENV !== 'development')
Sentry.init({dsn: "https://d2146613e9d94797b84e3592063bd5ff@sentry.io/3718386"});



// ReactDOM.render(
render(
<App />,
document.getElementById('root'));

