import React from 'react'

import cf from 'util/caravan-functions'

import { Badge } from 'bootstrap-4-react'


const CaravanStats = ({caravan}) => {
    // if (true)
    // return null;
    return (
    <div className="hidden">
    <Badge className="badge-info">joined: {cf.formatDate(caravan.stats.joinDate)}</Badge>
    <Badge className={`hidden ${cf.dateLabelClass(caravan.stats.lastActive)}`}>last visit: {cf.formatDate(caravan.stats.lastActive, "-")}</Badge>
    <Badge className={`hidden ${cf.dateLabelClass(caravan.stats.lastLogon)}`}>last logon: {cf.formatDate(caravan.stats.lastLogon, "-")}</Badge>
    <Badge className={caravan.stats.nsent === 0 ? 'badge-danger' : caravan.stats.nsent > 5 ? 'badge-success' : ''}>messages sent: {caravan.stats.nsent}</Badge>

    <Badge className='badge-primary '>messages received: {caravan.stats.nreceived}</Badge>
    <Badge className={`${cf.dateLabelClass(caravan.stats.lastSent)}`}>last sent message: {cf.formatDate(caravan.stats.lastSent, "-")}</Badge>

    <Badge className='badge-primary hidden'>last received message: {cf.formatDate(caravan.stats.lastReceived, "-")}</Badge>
    </div>
);
    }
export default CaravanStats;