import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Row, Col, Card, Container, Button, Breadcrumb, Nav, Alert } from 'bootstrap-4-react'

import { AuthUserContext } from '_registration/util/session';
import * as ROUTES from '../../constants/routes';
import CaravanList from 'components/caravan/caravan-list';
import Spinner from '_common/util/Spinner'
import ErrorMessage from '_common/components/ErrorMessage'
import _ from 'lodash';

import serviceFactory from 'services/ServiceFactory';

import MessageList from 'components/message/message-with-caravan-list';

const messageWithCaravanService = serviceFactory.getMessageWithCaravanService();
const messageService = serviceFactory.getMessageService();


const MessageCenterPage = () => {
  const authUser = useContext(AuthUserContext);

  const [messages, setMessages] = useState();
  const [inbox, setInbox] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();



  useEffect(() => {
    

    let mounted = true;

    const fetchData = async () => {

      try {
        setLoading(true);
        const messages = inbox ? await messageWithCaravanService.getUserInbox() : await messageWithCaravanService.getUserSent();

        if (mounted) {
          setMessages(messages);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        if (mounted) {
          setLoading(false);
          setError(err);
        }
      }
    };
    fetchData();

    return (() => mounted = false)
  }, [inbox]);


  const doDelete = async (messageID) => {
    
    try {
      let origMessages = [...messages];

      inbox ? await messageService.deleteInboxMessage({ messageID }) : await messageService.deleteSentMessage({ messageID });
      _.remove(origMessages, (m) => m.id === messageID);
      setMessages(origMessages);

    }
    catch (e) {
      
      setError(e);
    
    }

  }

  return (
    <>
      {loading ? <Spinner /> :

        <>

          <nav aria-label="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item><Link to={ROUTES.HOME}>Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item active aria-current="page">Messages</Breadcrumb.Item>
            </Breadcrumb>
          </nav>
          <Container>

          <Nav tabs>
            <Nav.ItemLink className={inbox ? "active" : ""} href="/messages" onClick={(e) => { e.preventDefault(); setInbox(true) }}>Inbox</Nav.ItemLink>
            <Nav.ItemLink className={!inbox ? "active" : ""} href="/messages" onClick={(e) => { e.preventDefault(); setInbox(false) }}>Sent</Nav.ItemLink>
          </Nav>

          {error && <Alert warning><ErrorMessage error={error} /></Alert>}

          <MessageList messages={messages} inbox={inbox} doDelete={doDelete} />

        </Container>

</>
      }
    </>
  )
}

// const ListPanel = () =>(
// <Router>
// <PrivateRouteTypeA path="/messages" render={(routerProps) => <MessageCenterPage {...routerProps} />
// </Router>

// )

export default MessageCenterPage
