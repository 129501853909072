import React from 'react';

import { Container } from 'bootstrap-4-react'
import { ConfirmNewEmailMessage as BaseConfirmNewEmailMessage } from '_registration/components/email-address';

import * as ROUTES from 'constants/routes';

const ConfirmNewEmailMessage = () => (
    <Container>
        <BaseConfirmNewEmailMessage successUrl={ROUTES.EMAIL_CONFIRMED_SUCCESS_URL} />
    </Container>
)
export default ConfirmNewEmailMessage

