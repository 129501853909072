import userServiceDummy from './dummy/UserService';
import userService from './UserService';

const isDev=()=> process.env.NODE_ENV === 'development';
const isDummy=() => isDev() && process.env.REACT_APP_DUMMY_DATA_USER;


const getUserService =()=>{
    if (isDummy()){
        return userServiceDummy
    }
    else
        return userService;
}


const serviceFactory = {
    getUserService
}

export default serviceFactory;