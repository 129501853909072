import * as CONSTANTS from './index'

export const HOME = '/';



//****************Required for registration */
//always required as a fallback:
export const ACCOUNT_HOME = '/account-home';

//always required as a fallback:
export const LOGIN = '/login';

export const LOGOUT = '/logout';

export const RESET_PASSWORD = '/reset-password';
export const CHANGE_EMAIL = '/change_email';

export const PLEASE_CONFIRM_EMAIL = '/confirm-email';
export const PLEASE_ACTIVATE_ACCOUNT = '/activate-account';

// export const EMAIL_CONFIRMED_SUCCESS_URL='https://boatvanexchangeclub'+ACCOUNT_HOME;
export const EMAIL_CONFIRMED_SUCCESS_URL=CONSTANTS.CEC_URL+ACCOUNT_HOME;

export const REGISTER = '/register';

//********************************END  */




export const CARAVAN_NOT_APPROVED = '/not-approved-yet';

// export const CARAVAN_LIST = '/boats-for-exchange/:page?';
export const CARAVAN_LIST = '/boats-for-exchange';
export const MY_CARAVAN = '/my-boat';
export const MY_CARAVAN_EDIT = '/my-boat/edit';

export const CARAVAN_DETAIL = '/boat/detail'
export const CARAVAN_DETAIL_PATH = '/boat/detail/:caravanUID/:caravanSeoString?'

export const MESSAGE_CENTER = '/messages'


export const CONTACT_US = '/contact-us';
export const CONTACT_US_SENT = '/contact/sent';

