import React from 'react';
import * as Yup from 'yup';
import { useHistory, Link } from "react-router-dom";


import { Display4, Button, Card } from 'bootstrap-4-react'
import { TextArea, TextInput, Select, CheckboxSingle } from '_common/util/formik-bootstrap-controls'
import { TractorIcon } from '_icons'
import * as ROUTES from 'constants/routes';
import FormBase from '_form'

import caravanForm from 'components/caravan/caravan-form';

import serviceFactory from 'services/ServiceFactory';
const caravanService = serviceFactory.getCaravanService();




const CaravanEdit = ({ caravan}) => {
    const history = useHistory();

    return (
        <FormBase
            initialData={caravanForm.getInitialValues(caravan)}
            validationScheme={caravanForm.VALIDATION_SCHEMA}
            success={() => history.replace(ROUTES.MY_CARAVAN)}
            cancel={() => history.replace(ROUTES.MY_CARAVAN)}
        submitLabel="Save"
            
             saveForm={(values) => caravanService.updateCaravan(values)}
        >
            {({values}) => (
            <caravanForm.FormFragment values={values}/>
            )}
        </FormBase>
    )
}

export default CaravanEdit;