import React, { Component, useState, useEffect } from 'react';
import { withRouter, useLocation, Redirect } from 'react-router-dom';
import PropTypes from "prop-types";
import { compose } from 'recompose';
import { Form, Button, Alert } from 'bootstrap-4-react';

import SpinnerOverlay from '_common/util/SpinnerOverlay'
import Spinner from '_common/util/Spinner'

import ErrorMessage from '_common/components/ErrorMessage';
import serviceFactory from '_registration/services/ServiceFactory'
const userService = serviceFactory.getUserService();

// import {c_log} from '_common/util/logger'


const LoginDelayed = ({ fwd }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    let isMounted = true;

    setTimeout(function () {
      if (isMounted) {
        setLoading(false);
        setShow(true);
      }

    }, 3000);

    return () => {
      // clean up
      isMounted = false;
    };
  })

  return (
    <>
      {show && (fwd ?
        <Redirect to={{ pathname: "/login", state: { ...location.state, fwd: location.pathname } }} />
        :
        <Redirect to={{ pathname: "/login" }} />
      )
      }

      {loading && <Spinner />}

    </>
  );

}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  loading: false,
  redirect: undefined
};

class LoginFormBase extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }


  onSubmit = event => {
    try {
      event.preventDefault();


      if (this.isInvalid())
        return;

      this.setState({ loading: true, error: null });

      const { email, password } = this.state;
      const { success, confirmEmail, postLogin, notApproved } = this.props;
      const fwd = this.props.location.state ? this.props.location.state.fwd : null;

      userService
        .loginWithEmailAndPassword({ email, password })
        .then((user) => {
          if (user.emailVerified && user.approved && postLogin)
            return postLogin(user)

          return user;
        })
        .then((user) => {

          let redirect;
          if (!user.emailVerified)
            return confirmEmail();
          else if (!user.approved)
            return notApproved();
          else {


            if (fwd)
              redirect = fwd;
            else {
              return success();
            }

          }
          this.setState({ ...INITIAL_STATE, redirect });
        })
        .catch(error => {
          this.setState({ error, loading: false });
        });

    }
    catch (error) {
      this.setState({ ...INITIAL_STATE, error });
    }
  };

  isInvalid() {
    const { email, password } = this.state;
    return password === '' || email === '';
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };



  render() {
    const { redirect } = this.state;
    const location = this.props.location;

    if (redirect)
      return <Redirect to={{ pathname: redirect, state: { ...location.state } }} />

    const { email, password, error, loading } = this.state;
    return (
      <>
        <SpinnerOverlay loading={loading}>
          <div className="formContainer">
            <Form onSubmit={this.onSubmit} method='POST'>
              <Form.Group>
                <label htmlFor="email">Email address</label>
                <Form.Input type="email" id="email" name="email"
                  value={email}
                  onChange={this.onChange} />

              </Form.Group>
              <Form.Group>
                <label htmlFor="password">Password</label>
                <Form.Input type="password" id="password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                />
              </Form.Group>

              <Button primary lg type="submit" disabled={loading} id="submit"> Login</Button>
              {error && <Alert danger style={{ marginTop: '10px' }}><ErrorMessage error={error} /></Alert>}
            </Form>
          </div>
        </SpinnerOverlay>

      </>
    );
  }
}

LoginFormBase.propTypes = {
  success: PropTypes.func.isRequired,
  confirmEmail: PropTypes.func.isRequired
};

const LoginForm = compose(
  withRouter
)(LoginFormBase);

export default LoginForm;

export { LoginDelayed }