import React, { useState, useContext } from 'react'

import * as Yup from 'yup';

import LoginLink from '_common/components/login-link'
import { TextArea, Hidden } from '_common/util/formik-bootstrap-controls'
import { Alert } from 'bootstrap-4-react';
import { AuthUserContext } from '_registration/util/session';

import * as ROUTES from 'constants/routes';
import FormBase from '_form'

import serviceFactory from 'services/ServiceFactory';
const messageService = serviceFactory.getMessageService();


const MessageForm = ({toUID, replyToUID, submitLabel="Send Message"}) => {
    const authUser = useContext(AuthUserContext);
    const [messageSent, setMessageSent] = useState(false);
    
    const INITIAL_FORM_VALUES = {
        message: '',
        toUID,
        replyToUID
    }


    const VALIDATION_SCHEMA = {
        message: Yup.string()
            .required('Required')
    }

    const success = () => setMessageSent(true);

    return (
        <>
            {!authUser ?
            <NeedLogin />
            :            
            messageSent ?
                <MessageSent />
                :

                <FormBase
                    initialData={INITIAL_FORM_VALUES}
                    validationScheme={VALIDATION_SCHEMA}
                    success={() => success()}

                   

                    saveForm={(values) => messageService.sendMessage(values)}
                    submitLabel={submitLabel}
                >
                    {({values}) => (
                    <fieldset className="form-group">
                        <Hidden name="toUID" />
                    
                        <TextArea name="message" label="Message" className="vlg" />

                    </fieldset>
                    )}
                </FormBase>
            }
        </>
    );
}

const MessageSent = () => <Alert success className="text-center"><p>Message Sent!</p><p>Replies will be sent to your email address and your Message Inbox</p></Alert>
const NeedLogin = () => <Alert warning className="text-center"><p>You need to <LoginLink><b>login</b></LoginLink> to contact owners</p></Alert>

export default MessageForm;