

import { format, subDays, isBefore } from 'date-fns'

const dateFormat = "MMM dd yyyy";


//y
const formatDate = (date, alternative) => {
	try {
		if (date) {
			return format(date, dateFormat);
		}
	} catch (e) { }
	return alternative ? alternative : '-';
}

const getDateFromStr=(dateStr) => {
	if (dateStr)
		return new Date(dateStr);
	else
		return null;
}



//y
const urlIt = (textToGoInUrl) => {
	return textToGoInUrl.replace(/[^A-Za-z0-9]/g, "-");
}



//y
const bedrooms = (val) => {
	return (val > 4) ? "5 +" : val;
}

//y
const sleeps = (val) => {
	return (val > 9) ? "10 +" : val;
}


const _convertOutsideValue = (dbValue) => {
	switch (dbValue) {
		case "bar": return "bar/club house";
		case "indoor pool": return "indoor pool";
		case "outdoor pool": return "outdoor pool";
		case "play area": return "play area";
		case "leisure": return "leisure facilities";
		case "amusements": return "amusements";
		default: return "";
	}
}

const convertOutsideList = (lst) => {
	return _convertList(lst, _convertOutsideValue)
}



const _convertInsideValue = (dbValue) => {
	switch (dbValue) {
		case "TV": return "tv";
		//		case Sky: return "Sky tv etc";
		case "stereo": return "music system";
		case "internet": return "internet";
		case "decking": return "decking";
		case "bbq": return "bbq";
		//case FREEVIEW: return "Freeview";
		case "dvd": return "dvd";
		default: return "";
	}
}
const convertInsideList = (lst) => {
	return _convertList(lst, _convertInsideValue)
}




const _convertPeriodValue = (dbValue) => {
	switch (dbValue) {
		case "anything considered": return "anything";
		case "weekends": return "weekends";
		case "week": return "week";
		case "month": return "month";
		case "season": return "half/full season";
		default: return "";
	}
}

const convertPeriodList = (lst) => {
	return _convertList(lst, _convertPeriodValue)
}


const _convertList = (dbList, converter) => {
	dbList = _makeSureItsList(dbList);

	let res = [];
	dbList.forEach((val) => {
		const convertedVal = converter(val);
		if (convertedVal !== "")
			res.push(convertedVal)
	})
	return res;
}

const _makeSureItsList = (val) => {
	//old migrated stuff its a csv string not array
	//so convert to array
	let lst;
	if (!val) {
		lst = []
	}
	else if (typeof val === "string") {
		lst = val.split(",");
	}
	else {
		lst = val;
	}

	return lst;
}



//'Children OK','Pets OK','NO smoking','NO single sex groups','Seniors only')
const _convertWhatsOKValue = (dbValue) => {
	switch (dbValue) {
		case "Children OK": return "Children OK";
		case "Pets OK": return "Pets OK";
		case "Smoking OK": return "Smoking OK";
		default: return "";
	}
}

const convertWhatsOKListList = (lst) => {
	return _convertList(lst, _convertWhatsOKValue)
}


//NOT NEEDED
//list.join
// public  String getWhatsOkList(List<WhatsOkType> list){
// 	StringBuffer buf = new StringBuffer();
// 	for (WhatsOkType item : list){
// 		buf.append(getWhatsOk(item)+",");
// 	}

// 	return buf.length()>0?buf.substring(0,buf.length()-1):"";
// }



//NOT NEEDED
// private  String getWhatsOk(WhatsOkType type){	
// 	switch (type){
// 	case CHILDREN_OK: return "Children OK";
// 	case PETS_OK: return "Pets OK";
// 	case NO_SMOKING: return "NO smoking";
// 	case NO_SINGLE_SEX_GROUPS: return "NO single sex groups";
// 	case SENIORS_ONLY: return "Seniors only";
// 	default: return "";
// 	}
// }



const convertType = (type) => {
	switch (type) {
		case "caravan": return "Static Caravan";
		case "chalet": return "Chalet";
		case "lodge": return "Lodge";
		case "tourer": return "Touring caravan";
		default: return "";
	}
}




const open = (openType, startMonth, endMonth) => {
	switch (openType) {
		case "all year": return "all year";
		case "season":
			return "seasonal (" + startMonth + " - " + endMonth + ")";
		default: return "";
	}
}






const available = (availableType, availableMonths) => {
	switch (availableType) {
		case "flexible": return "flexible, open to offers";
		case "fixed": return availableMonths && availableMonths.join();
		default: return "";
	}
}


const dateLabelClass = (date) => {
	if (date == null)
		return "badge-primary";

	const today = new Date();

	let result = subDays(today, 60)

	if (isBefore(date, result)) {
		return "badge-danger";
	}
	else {
		result = subDays(today, -30)
		if (isBefore(date, result)) {
			return "badge-warning";
		}
	}
	return "badge-primary";
}


const seoStr = (caravan) => `Caravan Holiday Exchange ${caravan.country} ${caravan.county} ${caravan.town}`;


const cf = {
	dateLabelClass,
	formatDate,
	urlIt,
	bedrooms,
	sleeps,
	available,
	open,	
	convertInsideList,
	convertOutsideList,
	convertPeriodList,
	convertWhatsOKListList,
	convertType,
	seoStr,
	getDateFromStr
}

export default cf;