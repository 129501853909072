import React from 'react'

import { Container } from 'bootstrap-4-react'

const CaravanNotApprovedPage = () => (
    <Container>
        <p className="text-center">Your boat advert has not been approved yet. We just need to check it. We will email you when it has and then you'll be able to login.</p>
    </Container>
)

export default CaravanNotApprovedPage;