import React from 'react'
import * as Yup from 'yup';

import * as CONSTANTS from 'constants/index';

import SelectCountryAndRegionBootstrap from '_common/components/select-region'

import { Row, Col, Card, Container, Button, Badge } from 'bootstrap-4-react'

import { TextArea, TextInput, Select, Checkbox, RadioGroup } from '_common/util/formik-bootstrap-controls'

export const INITIAL_VALUES = {
    country: '',
    county: '',
    town: '',
    heading: '',
    description: '',
    available: 'flexible',
    availableMonths: [],
    type:'canal boat',
    bedrooms:'',
    sleeps:'',
    boatLength:'',
    doublebeds:'',
    period:[],
    whatsOk:[],
    inside:[],
    outside:[]
}

export const VALIDATION_SCHEMA = {
    country: Yup.string()
        .required('Required'),
    county: Yup.string()
        .required('Required'),
    town: Yup.string()
        .required('Required'),
    heading: Yup.string()
        .required('Required'),
    description: Yup.string()
        .required('Required'),
}



const getInitialValues=(caravan)=>({    
    country: caravan.country,
    county: caravan.county,
    town: caravan.town,
    heading: caravan.heading,
    description: caravan.description,
    available: caravan.available,
    availableMonths: caravan.availableMonths,
    type: caravan.type,
    bedrooms:caravan.bedrooms,
    sleeps:caravan.sleeps,
    whatsOk:caravan.whatsOk,
    period:caravan.period?caravan.period:[],
    inside:caravan.inside,
    outside:caravan.outside,
    boatLength:caravan.boatLength,
    doublebeds:caravan.doublebeds
})

export const FormFragment = ({values}) => {

    
    
    const availableOptions = [
        { key: '0', value: 'flexible', text: 'flexible' },
        { key: '1', value: 'fixed', text: 'available during these months:' }
    ];
    
    
    const typeOptions = [
        { key: '1', value: 'narrowboat', text: 'narrowboat' },
        { key: '2', value: 'barge', text: 'barge' },
        { key: '3', value: 'cruiser', text: 'cruiser' },
        { key: '4', value: 'sailing', text: 'sailing boat' }
    ];
    
    const bedroomsOptions = [
        { key: '0', value: '', text: 'Please select ...' },
        { key: '1', value: '1', text: '1' },
        { key: '2', value: '2', text: '2' },
        { key: '3', value: '3', text: '3' },
        { key: '4', value: '4', text: '4' },
        { key: '5', value: '5', text: '5+' }
    ];
    
    
    const availableMonthsOptions = [
        { key: '1', value: 'January', text: 'January' },
        { key: '2', value: 'February', text: 'February' },
        { key: '3', value: 'March', text: 'March' },
        { key: '4', value: 'April', text: 'April' },
        { key: '5', value: 'May', text: 'May' },
        { key: '6', value: 'June', text: 'June' },
        { key: '7', value: 'July', text: 'July' },
        { key: '8', value: 'August', text: 'August' },
        { key: '9', value: 'September', text: 'September' },
        { key: '10', value: 'October', text: 'October' },
        { key: '11', value: 'November', text: 'November' },
        { key: '12', value: 'December', text: 'December' }
    
    ];
    
    
    //anything','weekends','week','month','season'
    const periodOptions = [
        { key: '1', value: 'anything considered', text: 'anything considered' },
        { key: '2', value: 'weekends', text: 'weekends' },
        { key: '3', value: 'week', text: 'week' },
        { key: '4', value: 'month', text: 'month' },
        { key: '5', value: 'season', text: 'half/full season' }
    ];
    
    
    const sleepsOptions = [
        { key: '0', value: '', text: 'Please select ...' },
        { key: '1', value: '1', text: '1' },
        { key: '2', value: '2', text: '2' },
        { key: '3', value: '3', text: '3' },
        { key: '4', value: '4', text: '4' },
        { key: '5', value: '5', text: '5' },
        { key: '6', value: '6', text: '6' },
        { key: '7', value: '7', text: '7' },
        { key: '8', value: '8', text: '8' },
        { key: '9', value: '9', text: '9' },
        { key: '10', value: '10', text: '10+' }
    ];
    
    const whatsOkOptions = [
        { key: '0', value: 'Children OK', text: 'Children OK' },
        { key: '1', value: 'Pets OK', text: 'Pets OK' },
        { key: '2', value: 'Smoking OK', text: 'Smoking OK' } 
    ];

    
    // const insideOptions = [
    //     { key: '1', value: 'TV', text: 'tv' },
    //     { key: '3', value: 'stereo', text: 'music system' },
    //     { key: '4', value: 'internet', text: 'internet' },    
    //     { key: '5', value: 'decking', text: 'terrace/decking' },    
    //     { key: '6', value: 'bbq', text: 'bbq' },    
    // ];
    

    // const outsideOptions = [
    //     { key: '1', value: 'bar', text: 'bar/club house' },
    //     { key: '2', value: 'indoor pool', text: 'indoor swimming pool' },
    //     { key: '3', value: 'outdoor pool', text: 'outdoor swimming pool' },
    //     { key: '4', value: 'play area', text: 'play area' },    
    //     { key: '5', value: 'leisure', text: 'leisure facilities' },    
    //     { key: '6', value: 'amusements', text: 'amusements' }
    // ];
    
    
    

return (
    <>
    <fieldset>
        <legend>Boat Location  (Required)</legend>
        <Card>
            <Card.Body>
                
               <SelectCountryAndRegionBootstrap selectedCountryValue={values.country}/>
                <TextInput type="text" name="town" label="*More Exact Location" placeholder="Chirk Marina on the Llangollen canal"/>                
            </Card.Body>
        </Card>
    </fieldset>

    <fieldset>
        <legend>Boat Details</legend>
        <Card>
            <Card.Body>

                <Select label="Boat Type" name="type" options={typeOptions} />
                <Select label="Num berths" name="bedrooms" options={bedroomsOptions} />
                <Select label="Sleeps" name="sleeps" options={sleepsOptions} />
                <TextInput type="text" label="Length" name="boatLength" placeholder="e.g. 37ft"/>
                <TextInput type="text" label="Num Double Beds" name="doublebeds" />
                <Checkbox label="What's Ok" name="whatsOk" options={whatsOkOptions} />
              
            </Card.Body>
        </Card>
    </fieldset>

    <fieldset>
        <legend>Describe your boat  (Required)</legend>
        <Card>
            <Card.Body>
                <TextInput type="text" name="heading" label="*Eye catching title for advert" placeholder="e.g. Lovely narrowboat in a marina"/>

                <TextArea name="description" label="*Introduce yourself, describe your boat, the surrounding area" className="vlg" placeholder="e.g. We are a retired couple who love our boat. We are interested in ...."/>
            </Card.Body>
        </Card>
    </fieldset>

    <fieldset>
        <legend>Availability</legend>
        <Card>
            <Card.Body>
                <RadioGroup noGroup={true} label="When do you prefer to exchange boats?" name="available" options={availableOptions} />
                <div style={{paddingLeft:'2rem', marginTop:'1rem'}}><Checkbox  label="" name="availableMonths" options={availableMonthsOptions} /></div>
                
                <Checkbox label="What periods of time would you consider exchanging for?" name="period" options={periodOptions} />
            </Card.Body>
        </Card>
    </fieldset>

    


    <fieldset>
        <legend>Photos</legend>
        <p>If you have any photos of your boat etc then simply email them to us anytime and we'll add them to your boat description<br />
            Email photos to: <a href={`mailto:${CONSTANTS.CEC_EMAIL}`}>{CONSTANTS.CEC_EMAIL}</a>
        </p>
    </fieldset>
</>
);
}


const caravanForm = {
    INITIAL_VALUES,
    VALIDATION_SCHEMA,
    FormFragment,
    getInitialValues
}

export default caravanForm;