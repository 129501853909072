import React from "react";

import { useField } from "formik";
import PropTypes from "prop-types";
import { Form } from 'bootstrap-4-react'


const SelectWithChildOptions = ({label, name, options, id=name, children }) => {
  const [field, meta] = useField({ name });
  const isError = meta.touched && meta.error;
  const valid = isError?false:'';

  return (
    <Form.Group>
      <label htmlFor={id}>{label}</label>
      <Form.Select id={id} valid={valid} name={name} {...field}>
      {children}
       
      </Form.Select>
      
      {(isError) ?
      <Form.Text text="danger">{meta.error}</Form.Text>: null}
    </Form.Group>
      
  );
};

SelectWithChildOptions.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,    
};
export { SelectWithChildOptions };
