import ma from '_common/util/missingArg'

import data from './caravan-data'
import cf from 'util/caravan-functions';


const getLatestCaravans =()=>{
  return Promise.resolve([]);
}

const createCaravan =( {country=ma(), county=ma(), town=ma(), heading=ma(), description=ma(),
available = 'flexible',
availableMonths= [],
type='caravan',
bedrooms='',
sleeps='',
whatsOk=[],
inside=[],
period=[],
outside=[]
}) =>{
  return Promise.resolve();
}


const updateCaravan=({country=ma(), county=ma(), town=ma(), heading=ma(),   
  description=ma(),
  available = ma(),
  availableMonths= ma(),
  type=ma(),
  bedrooms=ma(),
  sleeps=ma(),
  whatsOk=ma(),
  inside=ma(),
  period=ma(),
  outside=ma()})=>{
    return Promise.resolve();
}

const  updateLastLoginAndLastRead = ()=>{
  return Promise.resolve();
}


const getCaravanSummaries = ({ currentPage, itemsPerPage = 2 }) => {
  let res = [];
  data.map((item) => {
    return res.push(_fromJSONSummary(item))
  });
  return Promise.resolve(res);
}

const getCaravanSummariesPhotosFirst = ({ currentPage, itemsPerPage = 2 }) => {
  let res = [];
  data.map((item) => {
    return res.push(_fromJSONSummary(item))
  });
  return Promise.resolve(res);
}

const adminGetCaravanSummaries = ({sinceLastGeneratedTime}) => {
  let res = [];
  data.map((item) => {
    return res.push(_fromJSONSummary(item))
  });
  return Promise.resolve(res);
}


const getCaravanSummariesByListOfUID = (uidList=ma()) => {
  let res = [];
  data.map((item) => {
    return res.push(_fromJSONSummary(item))
  }); 
  return Promise.resolve(res);
}

const getUserCaravanSummary=() => {
  
  const res = _fromJSONSummary(data[0]);  
  return Promise.resolve(res);
}



const getCaravanDetail = (uid=ma())=>{
  
  const res = {..._fromJSONSummary(data[0]), 
        ..._fromJSONDetail(data[0].detail.description) }

  return Promise.resolve(res);
}

const getUserCaravanDetail = ()=>{
  //const uid= auth.getUser
  const res = {..._fromJSONSummary(data[0]), 
        ..._fromJSONDetail(data[0].detail.description) }

  return Promise.resolve(res);
}



// | Field            | Type                                                                                                                  | Null | Key | Default  | Extra |
// +------------------+-----------------------------------------------------------------------------------------------------------------------+------+-----+----------+-------+
// uid            | userID           | int(11)                                                                                                               | NO   | PRI | 0        |       |
//  ------- | title            | enum('Dr','Ms','Mrs','Mr')                                                                                            | YES  |     | NULL     |       |
// ---------| firstname        | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// ---------| lastname         | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// country  | country          | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// county   | county           | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// town     | town             | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// ---------| postcode         | varchar(10)                                                                                                           | YES  |     | NULL     |       |
// available| available        | enum('flexible','fixed')                                                                                              | YES  |     | flexible |       |
// period| period           | set('anything','weekends','week','month','season')                                                                    | YES  |     | anything |       |
// ---------| open             | enum('all year','season')                                                                                             | YES  |     | season   |       |
// ----------| seasonStartMonth | enum('Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec')                                         | YES  |     | Mar      |       |
// ---------| seasonEndMonth   | enum('Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec')                                         | YES  |     | Oct      |       |
// heading  | heading          | varchar(80)                                                                                                           | YES  |     | NULL     |       |
// type     | type             | enum('caravan','chalet','lodge','tourer')                                                                             | YES  |     | caravan  |       |
// bedrooms | bedrooms         | tinyint(4)                                                                                                            | YES  |     | NULL     |       |
// sleeps   | sleeps           | tinyint(4)                                                                                                            | YES  |     | NULL     |       |
// whatsOk  | whatsOk          | set('Children OK','Pets OK','NO smoking','NO single sex groups','Seniors only')                                       | YES  |     | NULL     |       |
// | location         | enum('in the mountains','near beach','near river','near lake','on beach','on lakeside','forest','countryside','city') | YES  |     | NULL     |       |
// inside    | inside           | set('TV','Sky','stereo','internet','decking','bbq','Freeview','dvd')                                                  | YES  |     | NULL     |       |
// outside   | outside          | set('bar','indoor pool','outdoor pool','play area','leisure','amusements')                                            | YES  |     | NULL     |       |
// description | description      | text                                                                                                                  | YES  |     | NULL     |       |
// availableMonths | availableDates   | varchar(200)                                                                                                          | YES  |     | NULL     |       |
// photos | photos           | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// ----------| deletedDateTime  | datetime                                                                                                              | YES  |     | NULL     |       |
// ----------| nviews           | int(11)                                                                                                               | YES  |     | 0        |       |
// +------------

const _fromJSONSummary = (doc) => {
  const data = doc.data;
  return {
    uid: doc.id,
    heading: data.heading,
    country: data.country,
    county: data.county,
    town: data.town,
    type: data.type,
    bedrooms: data.bedrooms,
    sleeps: data.sleeps,
    available: data.available,
    availableMonths: data.availableMonths,
    whatsOk: data.whatsOk,
    period : data.period,
    inside: data.inside,
    outside: data.outside,
    photos: data.photos,
    mainPhoto:data.mainPhoto,
    shortDescription:data.shortDescription,
    gold:data.gold,
    stats : {
      lastLogon: cf.getDateFromStr(data.lastLogon),
      lastRead: cf.getDateFromStr(data.lastRead),
      nsent: data.nsent,
      nreceived: data.nreceived,
      lastSent: cf.getDateFromStr(data.lastSent),
      lastReceived: cf.getDateFromStr(data.lastReceived),
      joinDate: cf.getDateFromStr(data.joinDate),
      lastLogonTime  : new Date(data.lastLogonTime)
    }
  }
}


//     +-------STATS ------+-----------------------------------------------------+------+-----+---------+-------+
// | Field           | Type                                                | Null | Key | Default | Extra |
// +-----------------+-----------------------------------------------------+------+-----+---------+-------+
// ---------------| userID          | int(11)                                             | NO   | PRI | NULL    |       |
// nsent         | nSent           | int(11)                                             | YES  |     | 0       |       |
// ----------------| nReplied        | int(11)                                             | YES  |     | 0       |       |
// nreceived     | nReceived       | int(11)                                             | YES  |     | 0       |       |
// ================| lastReplied     | date                                                | YES  |     | NULL    |       |
// lastSent      | lastSent        | date                                                | YES  |     | NULL    |       |
// lastRead      | lastRead        | date                                                | YES  |     | NULL    |       |
// lastReceived  | lastReceived    | date                                                | YES  |     | NULL    |       |
// --------| status          | enum('active','busy','inactive','suspend','notset') | YES  |     | active  |       |
// ---------| nExchanges      | int(11)                                             | YES  |     | 0       |       |
// ---------| nFeedback       | int(11)                                             | YES  |     | 0       |       |
// | deletedDateTime | datetime                                            | YES  |     | NULL    |       |
// +-----------------+-----------------------------------------------------+------+-----+---------+-------+
// 12 rows in set (0.06 sec)



const _fromJSONDetail = (doc) => {
  const data = doc.data;
  return {
    description: data.detail.description
  }
}


  const caravanService = {
    getCaravanSummaries,
    getCaravanSummariesPhotosFirst,
    getCaravanDetail,
    getUserCaravanDetail,
    getCaravanSummariesByListOfUID,
    createCaravan,
    updateCaravan,
    updateLastLoginAndLastRead,
    getUserCaravanSummary,
    adminGetCaravanSummaries,
    getLatestCaravans
  }

  export default caravanService;