import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';




const config_prod = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
    authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
    projectId: process.env.REACT_APP_PROD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_APP_ID,
    measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID    
};

const config_dev = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
    authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_APP_ID,
    measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID    
};

const config = process.env.NODE_ENV === 'production' ? config_prod : config_dev;
// const config = config_dev;
// const config = config_prod;


const firebase = app.initializeApp(config);

const auth = app.auth();

const db = app.firestore();

// Initialize your Web app as described in the Get started for Web
// firebaseApp previously initialized using firebase.initializeApp().
if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DUMMY_EMULATOR) {
  // Note that the Firebase Web SDK must connect to the WebChannel port
  db.settings({
    host: "localhost:8081",
    ssl: false
  });

  firebase.functions().useFunctionsEmulator("http://localhost:5001")
}

const storage = firebase.storage();

export default firebase;
export {app, auth,db, storage}



