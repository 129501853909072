import React from "react";
import { Route, Redirect, useLocation } from 'react-router-dom'


import {ActivateAccountMessage, ConfirmNewEmailMessage,Login, UpdateEmail,RegFormA,RegFormB,AccountHome} from 'components/register'
import {LoginDelayed} from '_registration/components/login'

import { AuthUserContext } from '../session';


const PrivateRoute = ({ component: Component, ...props }) =>{
  const location = useLocation();
  
  return(
  <div>
    <AuthUserContext.Consumer>
      {authUser =>{
        if (authUser && authUser.type !== props.regType){
          return <Route {...props} render={(innerProps) => <Redirect to="/account-home"/>} />
        }
        return authUser && (!props.regType || authUser.type === props.regType) ?
          <Route {...props} render={(innerProps) => <Component {...innerProps} />} />
          :
          <Route {...props}  render={()=><LoginDelayed fwd={location.pathname} />} />
          // <Route {...props} render={(innerProps) => <Redirect to={{pathname:"/login", state:{...location.state, fwd:location.pathname} }} />} />
          
      }}
    </AuthUserContext.Consumer>
  </div>
  );
    }


const PrivateRouteTypeA = (props) => <PrivateRoute regType="A" {...props} />

const PrivateRouteTypeB = (props) => <PrivateRoute regType="B" {...props} />






export default PrivateRoute

export {PrivateRouteTypeA, PrivateRouteTypeB}

