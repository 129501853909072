import {argsToString} from './util'

class ApiError extends Error {
  constructor(message, origError, args) {
    super(message+"\n"+argsToString(args?args:{})+" \n API error: "+origError.toString());
    this.name = "APIError";
  }

}

export default ApiError;