import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Container, Lead } from 'bootstrap-4-react'


import {GoldStarIcon} from '_icons'
// import FBImage from '_common/components/images/firebase-image'
import { AuthUserContext, withAuthentication } from '_registration/util/session';
// import * as ROUTES from '../../constants/routes';

// import slatestPhotos from './latest-photos'
// import LatestCaravans from 'components/caravan/latest-caravans'

const HomePage = () => {

  const authUser = useContext(AuthUserContext);

  return (
    <>
      <Container id="homepage">

      <Container>
                <div className="splash-back">
                   <div className="splash-back-overlay">
    
                    <h1 className="p display-1 text-center" >Boat holiday swap and exchange</h1>
                <Lead className="text-center " style={{fontSize:'1.5rem', fontWeight:"bold"}}>Holiday idea for owners of narrow boats, canal boats, barges or any type of boat</Lead>

                </div>
                </div>
                <div class="splash-credit">Photo by <a href="https://unsplash.com/@jameshomans?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">James Homans</a> on Unsplash</div>
                </Container>
            

      

        <Container>
          <Row>
            <Col col="md">
              <Card text="center">
                <Card.Header className="home"><h2>What is Boat Exchange Club?</h2></Card.Header>

                <Card.Body>
                The Boat Exchange Club lets boat owners, especially canal and river boat owners, <strong>exchange</strong> or <strong>swap</strong> their boat with someone else for a period of time. It gives them the opportunity to have holidays somewhere different for a change and explore new waterways. Maybe just for a weekend, a week, month or even more.
                </Card.Body>
              </Card>
            </Col>
            <Col col="md">
              <Card  >
                <Card.Header className="home text-center"><h2>How does it work?</h2></Card.Header>
                <Card.Body>
                  <ol>
                    <li>Register your boat</li>
                    <li>Browse our boat listings</li>
                    <li>Contact people and arrange an exchange</li>
                  </ol>
                </Card.Body>
              </Card>
            </Col>
            <Col col="md">
              <Card text="center" >
                <Card.Header className="home"><h2>How much does it cost?</h2></Card.Header>
                <Card.Body>
                  <p>It's free but for serious exchangers there is a Gold Membership option </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container style={{ marginTop: "2rem" }}>
          <a href="/register" className="btn btn-primary btn-lg btn-block">Register your Boat</a>
        </Container>

        <Container className ="box gold text-center">
      <p> <GoldStarIcon />  For a limited time all boats registered will become Gold Members and will be able to contact members of the popular <a href="https://caravanexchangeclub.net" target="_blank">Caravan Exchange Club</a></p>
      <div className="text-center">
      <a href="https://caravanexchangeclub.net" target="_blank" alt="Holiday exchange your boat with caravan owners"><img src="/images/caravan1.jpg" style={{ height: '100px' }} alt="Holiday exchange your boat with caravan owners"/></a>
      </div>
      </Container>


        <Container>

          {/* <Jumbotron > */}
            {/* <LatestCaravans /> */}
              {/* 
              <>
                <Row>
                  <Col>
                    <Link to={`${ROUTES.CARAVAN_DETAIL}/${latestPhotos[0].uid}/static-caravan`} ><img src={latestPhotos[0].url} /></Link>
                  </Col>
                  <Col>
                    <Link to={`${ROUTES.CARAVAN_DETAIL}/${latestPhotos[1].uid}/static-caravan`} ><img src={latestPhotos[1].url} /></Link>
                  </Col>
                  <Col>
                    <Link to={`${ROUTES.CARAVAN_DETAIL}/${latestPhotos[2].uid}/static-caravan`} ><img src={latestPhotos[2].url} /></Link>
                  </Col>

                </Row>

                <Row className="p">
                  <Col>
                    <Link to={`${ROUTES.CARAVAN_DETAIL}/${latestPhotos[3].uid}/static-caravan`} ><img src={latestPhotos[3].url} /></Link>
                  </Col>
                  <Col>
                    <Link to={`${ROUTES.CARAVAN_DETAIL}/${latestPhotos[4].uid}/static-caravan`} ><img src={latestPhotos[4].url} /></Link>
                  </Col>
                  <Col>
                    <Link to={`${ROUTES.CARAVAN_DETAIL}/${latestPhotos[5].uid}/static-caravan`} ><img src={latestPhotos[5].url} /></Link>
                  </Col>

                </Row>
              </>
             */}

            {/* <p className="text-center"><Link className="btn btn-primary btn-lg" to={ROUTES.CARAVAN_LIST}>Browse all Boats</Link></p> */}
          {/* </Jumbotron> */}

        </Container>
      </Container>
    </>


  )
}

export default withAuthentication(HomePage)
