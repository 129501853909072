import React from 'react';
import * as Yup from 'yup';
import { useHistory, Link } from "react-router-dom";

import { Card, Container } from 'bootstrap-4-react'
import { TextInput } from '_common/util/formik-bootstrap-controls'

import * as ROUTES from 'constants/routes';
import {RegisterNoDisplayNameForm} from '_registration/components/register';

import caravanForm from 'components/caravan/caravan-form';

import serviceFactory from 'services/ServiceFactory';
const caravanService = serviceFactory.getCaravanService();




const createFullProfile = (values) => {    
    return caravanService.createCaravan(values)    
}


const RegFormA = () => {
    const history = useHistory();
    return (
        <Container id="reg-form-A">


            <RegisterNoDisplayNameForm
                initialFormValues={caravanForm.INITIAL_VALUES}
                validationScheme={caravanForm.VALIDATION_SCHEMA}
                regType="B.NA"
                pleaseConfirmEmail={() => history.replace(ROUTES.PLEASE_ACTIVATE_ACCOUNT)}
                confirmedEmailSuccessUrl={ROUTES.EMAIL_CONFIRMED_SUCCESS_URL}
                createFullProfile={(values) => createFullProfile(values)}
                submitLabel="Submit"
            >
                {({values}) => (
                    <>

                        <fieldset>
                            <legend>Login Details</legend>
                            <Card>
                                <Card.Body>
                                    <TextInput type="email" name="email" label="Email" />

                                    <TextInput type="password" name="passwordOne" label="Password" />

                                    <TextInput type="password" name="passwordTwo" label="Confirm Password" />

                                </Card.Body>
                            </Card>




                        </fieldset>
                        <caravanForm.FormFragment values={values}/>

                    </>
                )}
            </RegisterNoDisplayNameForm>
        </Container>
    )
}

export default RegFormA;


