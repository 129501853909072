import { auth, db } from '../util/Firebase/firebase';

import ma from '_common/util/missingArg'
import ApiError from '_errors/ApiError'





const createGeneralMessage = ({email=ma(), name=ma(), message=ma()}) =>{
  return db.collection("contact_message_boat").doc().set(
      {
        email,
        name,
        message
      }
    )
    .catch(error => {
      throw (new ApiError("ContactUsService createGeneralMessage", error, {email, name, message}));
    })
}

const contactService ={
    createGeneralMessage

}

export default contactService;