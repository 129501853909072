
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Collapse, Navbar, Nav, Dropdown } from 'bootstrap-4-react';
import { LinkContainer } from 'react-router-bootstrap'

import ScrollMemory from 'react-router-scroll-memory';
import Helmet from 'react-helmet';


import * as ROUTES from '../../constants/routes';

import { PrivateRouteTypeB } from '_registration/util/private-route';
import { AuthUserContext, withAuthentication } from '_registration/util/session';
import ErrorBoundary from '_common/util/ErrorBoundary'

import { HomePage, ContactUsPage, CaravanDetailPage, 
  CaravanListPage, MyCaravanPage, MyCaravanEditPage, MessageCenterPage,CaravanNotApprovedPage } from 'components/pages'


import LogoutPage from '_registration/components/logout'
import ResetPassword from '_registration/components/reset-password'
import { ActivateAccountMessage, ConfirmNewEmailMessage, Login, UpdateEmail, RegFormA } from 'components/register'



//import StaticMain from 'components/static-pages';
//import TestPage from 'components/pages';






const App = () => {


  return (
    <ErrorBoundary>
        <Helmet>
       <title>Boat Exchange Club</title>
        <meta name="description" content="Staycation holiday swap and exchanges for boat owners e.g. narrow boat, barge, canal boat"/>
        <meta name="keywords" content="narrow boat, barge, cruiser, canal, river, holiday, exchange, staycation, swap" />
      </Helmet>
      <Router>
      <ScrollMemory />


        <Header />
        <Main />
        <Footer />

      </Router>

    </ErrorBoundary>

  )
}


const Header = () => {
  const authUser = useContext(AuthUserContext);
  return (
    <>

<div className='titleBar'>
    
    <Link to={ROUTES.HOME} className="websiteTitle logo">Boat Exchange Club</Link> 
    {/* <div   style={{float:'right'}} className="notSmall">
    <div style={{fontSize:'2rem', letterSpacing:'1rem'}}>
    <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.boatexchangeclub.net/" className="fab fa-facebook-f" target="_blank" rel="noopener noreferrer"></a> 
    <a href="https://twitter.com/intent/tweet?text=Static+caravan+and+chalet+holiday+exchanges++https%3A%2F%2Fwww.boatexchangeclub.net%0D%0A" className="fab fa-twitter" target="_blank" rel="noopener noreferrer"></a> 
    <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.boatexchangeclub.net/&amp;title=Caravan+Exchange+Club&amp;summary=Static+caravan+and+chalet+holiday+exchanges&amp;source=" className="fab fa-linkedin-in" target="_blank" rel="noopener noreferrer"></a>       
    </div>

    </div> */}
  </div>      
    <Navbar expand="sm" dark mb="3">
      <Navbar.Toggler target="#navbarColor1" />
      <Collapse navbar id="navbarColor1">
        <Navbar.Nav mr="auto">
          {authUser ? <NavigationAuth /> : <NavigationNonAuth />}
        </Navbar.Nav>
        {authUser &&
          <Navbar.Text><Link to={ROUTES.MY_CARAVAN} style={{ textDecoration: 'none' }}>{authUser.email}</Link></Navbar.Text>
        }
      </Collapse>
    </Navbar>
</>
  )
}



const Main = () => (
  <ErrorBoundary>
    
      <Route path={ROUTES.REGISTER} component={RegFormA} />
      <Route path={ROUTES.PLEASE_CONFIRM_EMAIL} component={ConfirmNewEmailMessage} />
      <Route path={ROUTES.PLEASE_ACTIVATE_ACCOUNT} component={ActivateAccountMessage} />
      <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
      <Route path={ROUTES.CHANGE_EMAIL} component={UpdateEmail} />
      <Route path={ROUTES.LOGIN} component={Login} />
      <Route path={ROUTES.LOGOUT} component={LogoutPage} />
      <PrivateRouteTypeB exact path={ROUTES.ACCOUNT_HOME} component={MyCaravanPage} />

      <Route exact path={ROUTES.HOME} component={HomePage} />



      <Route exact path={ROUTES.CONTACT_US} component={ContactUsPage} />
      

      
      <Route path={ROUTES.CARAVAN_DETAIL_PATH} component={CaravanDetailPage} />
      <Route path={ROUTES.CARAVAN_LIST} component={CaravanListPage} />
      
      
      <Route exact path={ROUTES.CARAVAN_NOT_APPROVED} component={CaravanNotApprovedPage} />
      <PrivateRouteTypeB exact path={ROUTES.MY_CARAVAN} component={MyCaravanPage} />
      <PrivateRouteTypeB exact path={ROUTES.MY_CARAVAN_EDIT} component={MyCaravanEditPage} />
      <PrivateRouteTypeB path={ROUTES.MESSAGE_CENTER} component={MessageCenterPage} />


      
      
      {/* <Route path="/static-pages"  component={StaticMain} /> */}
      {/* <Route path="/test"  component={TestPage} /> */}
  

   

  </ErrorBoundary>

)


const Footer = () =>
  <div id="footer">
    <hr />
    <div>Boat Exchange Club - <a target="_blank" rel="noopener noreferrer" href="/privacy.html">privacy</a> | <a target="_blank" rel="noopener noreferrer" href="/tandc.html">terms and conditions</a> | <a href="/static-matic/boats/index.html">boats</a> | <a href="/static-matic/resources/index.html">resources</a></div>

    {/* <ArmStrength /> */}
  </div>




const NavigationAuth = () => (
  <>
  <LinkContainer to="/"><Nav.ItemLink>Home</Nav.ItemLink></LinkContainer>
    <LinkContainer to="/logout"><Nav.ItemLink>Logout</Nav.ItemLink></LinkContainer>
    <Nav.Item dropdown>
      <Nav.Link dropdownToggle>Your Boat</Nav.Link>
      <Dropdown.Menu>
        <LinkContainer exact to={ROUTES.MY_CARAVAN}><Dropdown.Item>View your boat</Dropdown.Item></LinkContainer>
        <LinkContainer to={ROUTES.MY_CARAVAN_EDIT}><Dropdown.Item id="empContactUsLink">Edit your advert</Dropdown.Item></LinkContainer>
        {/* <LinkContainer to="/my-caravan.do?action=updateStatsF"><Dropdown.Item>Edit advert status (suspend, delete etc)</Dropdown.Item></LinkContainer> */}

      </Dropdown.Menu>
    </Nav.Item>

    <LinkContainer to={ROUTES.MESSAGE_CENTER}><Nav.ItemLink>Your Messages</Nav.ItemLink></LinkContainer>

    <CommonNavigation />

  </>
);

const NavigationNonAuth = () => (
  <>
  <LinkContainer to="/"><Nav.ItemLink>Home</Nav.ItemLink></LinkContainer>
    <LinkContainer to="/login"><Nav.ItemLink>Login</Nav.ItemLink></LinkContainer>
    <LinkContainer to="/register"><Nav.ItemLink>Register</Nav.ItemLink></LinkContainer>
    <CommonNavigation />
  </>
);

const CommonNavigation = () => (
  <>
  <LinkContainer to={ROUTES.CARAVAN_LIST}><Nav.ItemLink>View Boats</Nav.ItemLink></LinkContainer>
    {/* <Nav.Item dropdown>
      <LinkContainer exact to="/caravans-for-exchange"><Nav.Link dropdownToggle>View Boats</Nav.Link></LinkContainer>
      <Dropdown.Menu>
        <LinkContainer exact to="/caravans-for-exchange"><Dropdown.Item>Most recently active first</Dropdown.Item></LinkContainer>
        {/* <LinkContainer to="/caravans-for-exchange?sort=ACTIVE"><Dropdown.Item >Most recent active members</Dropdown.Item></LinkContainer> */}
        {/* <LinkContainer to="/caravans-for-exchange?filter=PHOTOS"><Dropdown.Item>Caravans with photos first</Dropdown.Item></LinkContainer> */}
        {/* <LinkContainer to="/caravans-for-exchange?sort=MESSAGES_SENT"><Dropdown.Item>Caravans who've sent the most exchange messages</Dropdown.Item></LinkContainer> */}
        {/* <LinkContainer to="/caravans-for-exchange?sort=MESSAGES_RECEIVED"><Dropdown.Item>Caravans who've received the most exchange messages</Dropdown.Item></LinkContainer> */}

      {/* </Dropdown.Menu> */}
    {/* </Nav.Item> */} 
    <LinkContainer to="/contact-us"><Nav.ItemLink>Contact Us</Nav.ItemLink></LinkContainer>
  </>
)






export default withAuthentication(App);