import React from 'react'
import { useHistory, Link } from "react-router-dom";

import { Container } from 'bootstrap-4-react';
import * as ROUTES from 'constants/routes';

import {ExclamationIcon} from "_icons";
import { c_error } from '_common/util/logger';
import LoginForm from '_registration/components/login';
import serviceFactory from 'services/ServiceFactory';
const caravanService = serviceFactory.getCaravanService();

const Login = (props) => {
    const history = useHistory();

    const postLogin = (user) => {
        try {            
            caravanService.updateLastLoginAndLastRead();

        }
        catch (error) { c_error(error); }
        return Promise.resolve(user);
    }

    return (
        <Container id="login">
            {/* <Display4 >Login</Display4> */}
            <LoginForm
                success={() => history.replace(ROUTES.ACCOUNT_HOME)}
                confirmEmail={() => history.replace(ROUTES.PLEASE_CONFIRM_EMAIL)}
                notApproved={() => history.replace(ROUTES.CARAVAN_NOT_APPROVED)}
                postLogin={(user) => postLogin(user)}
                {...props}
            />
            <Link to={ROUTES.RESET_PASSWORD}>Forgot/Reset Password</Link>
        </Container>
    )
}
export default Login;