import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const LoginLink = ({children})=>{
    const location = useLocation();

    return (<Link to={{pathname:"/login", state:{...location.state, fwd:location.pathname} }}>{children}</Link> )

}

export default LoginLink;




