import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Card, Container, Button, Badge } from 'bootstrap-4-react'

import * as ROUTES from '../../constants/routes';
import FBImage from '_common/components/images/firebase-image'
import cf from 'util/caravan-functions'
import {GoldStarIcon} from '_icons'

import CaravanStats from './caravan-stats'
import CaravanBits from './caravan-bits'
import CaravanHeader from './caravan-header'



const CaravanSummary = ({ caravan, index }) => {
  const alternatingClass = ['shade1', 'shade2'];
  const clazz = alternatingClass[index % alternatingClass.length];
  const cardClass = caravan.gold?'gold caravan_summary':'caravan_summary';

  return (
    <Card className={cardClass} id={'cvn_summary_'+caravan.uid} >
      <Card.Header className={clazz}><h2 id={"cvn_header_"+caravan.uid}><CaravanHeader caravan={caravan} /></h2></Card.Header>
      <Card.Body>
        <div className="pull-right-sm" id={"cvn_photo_"+caravan.uid}>          
          {(caravan.photos && caravan.photos.length) ? <Link to={`${ROUTES.CARAVAN_DETAIL}/${caravan.uid}/${cf.urlIt(caravan.country + " " + caravan.county + " " + caravan.town)}`} ><FBImage className="img-thumbnail cvn-summary" alt={cf.seoStr(caravan)} title={cf.seoStr(caravan)} srcPath={`/images/user/${caravan.uid}_1.jpg`} style={{ width: "200px" }} /></Link>
          :
          <img className="img-thumbnail" src="https://via.placeholder.com/200x150.png?text=No+Photo" />

  }

        </div>

        <CaravanBits caravan={caravan} detailed={false} />

        <h3 id={"cvn_heading_"+caravan.uid}>{caravan.heading}</h3>
        <p id={"cvn_shortdesc_"+caravan.uid}>{caravan.shortDescription}......</p>
        <p>
          <Link className="btn btn-primary" to={`${ROUTES.CARAVAN_DETAIL}/${caravan.uid}/${cf.urlIt(caravan.country + " " + caravan.county + " " + caravan.town)}`}>View Details or Contact</Link>
        </p>

      </Card.Body>
      <Card.Footer className="text-right">
        {caravan.gold && <><GoldStarIcon /> Gold Member</>}
        <CaravanStats caravan={caravan} />
      </Card.Footer>
    </Card>
  )
}

export default CaravanSummary;