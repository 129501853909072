import React from 'react';
import AuthUserContext from './context';
import serviceFactory from '_registration/services/ServiceFactory'
import {REG_EMAIL_VERIFICATION_REQUIRED, REG_APPROVAL_REQUIRED_TO_SIGN_ON} from 'constants/index'

const userService = serviceFactory.getUserService();

// import {c_log} from '_common/util/logger'


const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    
    constructor(props) {
      super(props);
      this.state = {
       // authUser: localStorage.getItem("authUser")
       authUser : null
      };
    }

    callback =(authUser)=>{
      if (authUser && (!REG_EMAIL_VERIFICATION_REQUIRED || authUser.emailVerified) && 
      (!REG_APPROVAL_REQUIRED_TO_SIGN_ON || authUser.approved)){
        this.setState({ authUser });
      }else{
        this.setState({ authUser: null });
      }
    }

    componentDidMount() {
        this.listener = userService.subscribeUserChange(this.callback);
     
    }

    componentWillUnmount() {
      try{
      this.listener.unsubscribe();
      }
      catch(e){
        
      }
    }

    render() {     
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
