import React from 'react'

import CaravanSummary from './caravan-summary';

const CaravanList = (props) =>{
    const {caravans} = props;

    return(
        caravans.map((caravan, index) => <CaravanSummary key={caravan.uid} caravan={caravan} index={index}/>)
    );

}


export default CaravanList;