import React from 'react';

import { TextInput, Select, SelectWithChildOptions } from '_common/util/formik-bootstrap-controls'
import ma from '_common/util/missingArg';


const COUNTRY_OPTIONS = [
    { key: '0', value: '', text: 'Please select ...' },
    { key: '1', value: 'England', text: 'England' },
    { key: '2', value: 'Wales', text: 'Wales' },
    { key: '3', value: 'Scotland', text: 'Scotland' },
    { key: '4', value: 'Northern Ireland', text: 'Northern Ireland' },
    { key: '5', value: 'Republic of Ireland', text: 'Republic of Ireland' },
    { key: '6', value: 'France', text: 'France' },
    { key: '7', value: 'Spain', text: 'Spain' },
    { key: '8', value: 'Other', text: 'Other' }
];

const SelectCountryAndRegionBootstrap = ({selectedCountryValue = ma()})=>(
    <>
    <Select label="*Country" name="country" options={COUNTRY_OPTIONS}  />
    {selectedCountryValue==='England' && <SelectRegionEngland />}
    {selectedCountryValue==='Wales' && <SelectRegionWales />}
    {selectedCountryValue==='Scotland' && <SelectRegionScotland />}
    {selectedCountryValue==='Northern Ireland' && <SelectRegionNorthernIreland />}
    {selectedCountryValue==='Republic of Ireland' && <SelectRegionIreland />}
    {selectedCountryValue==='Other' && <TextInput type="text" name="county" label="*Enter Country &amp; Region" /> }
    </>
)

export default SelectCountryAndRegionBootstrap;

const SelectRegionEngland =({name="county", label="*County/Region"})=>(
    <SelectWithChildOptions label={label} name={name}>
        <option value="">Please select ...</option>
    <RegionOptionsEngland />
    </SelectWithChildOptions>
)

const SelectRegionNorthernIreland =({name="county", label="*County/Region"})=>(
    <SelectWithChildOptions label={label} name={name}>
        <option value="">Please select ...</option>
    <RegionOptionsNorthernIreland />
    </SelectWithChildOptions>
)

const SelectRegionScotland =({name="county", label="*County/Region"})=>(
    <SelectWithChildOptions label={label} name={name}>
        <option value="">Please select ...</option>
    <RegionOptionsScotland />
    </SelectWithChildOptions>
)


const SelectRegionWales =({name="county", label="*County/Region"})=>(
    <SelectWithChildOptions label={label} name={name}>
        <option value="">Please select ...</option>
    <RegionOptionsWales />
    </SelectWithChildOptions>
)

const SelectRegionIreland =({name="county", label="*County/Region"})=>(
    <SelectWithChildOptions label={label} name={name}>
        <option value="">Please select ...</option>
    <RegionOptionsIreland />
    </SelectWithChildOptions>
)

const RegionOptionsEngland=()=>(
    <>
    <option value="Avon">Avon</option>
<option value="Bedfordshire">Bedfordshire</option>
<option value="Berkshire">Berkshire</option>
<option value="Bristol, City of">Bristol, City of</option>
<option value="Buckinghamshire">Buckinghamshire</option>
<option value="Cambridgeshire">Cambridgeshire</option>
<option value="Cheshire">Cheshire</option>
<option value="Cleveland">Cleveland</option>
<option value="Cornwall">Cornwall</option>
<option value="Cumbria">Cumbria</option>
<option value="Derbyshire">Derbyshire</option>
<option value="Devon">Devon</option>
<option value="Dorset">Dorset</option>
<option value="Durham">Durham</option>

<option value="Essex">Essex</option>
<option value="Gloucestershire">Gloucestershire</option>
<option value="Greater London">Greater London</option>
<option value="Greater Manchester">Greater Manchester</option>
<option value="Hampshire">Hampshire</option>
<option value="Hereford and Worcester">Hereford and Worcester</option>
<option value="Herefordshire">Herefordshire</option>
<option value="Hertfordshire">Hertfordshire</option>
<option value="Humberside">Humberside</option>
<option value="Isle of Wight">Isle of Wight</option>
<option value="Kent">Kent</option>
<option value="Lancashire">Lancashire</option>
<option value="Leicestershire">Leicestershire</option>
<option value="Lincolnshire">Lincolnshire</option>
<option value="London">London</option>
<option value="Merseyside">Merseyside</option>
<option value="Middlesex">Middlesex</option>
<option value="Midlands">Midlands</option>
<option value="Norfolk">Norfolk</option>
<option value="Northamptonshire">Northamptonshire</option>
<option value="Northumberland">Northumberland</option>
<option value="Nottinghamshire">Nottinghamshire</option>
<option value="Oxfordshire">Oxfordshire</option>
<option value="Rutland">Rutland</option>
<option value="Shropshire">Shropshire</option>
<option value="Somerset">Somerset</option>
<option value="Staffordshire">Staffordshire</option>
<option value="Suffolk">Suffolk</option>
<option value="Surrey">Surrey</option>
<option value="Sussex">Sussex</option>
<option value="Tyne and Wear">Tyne and Wear</option>
<option value="Warwickshire">Warwickshire</option>
<option value="Wiltshire">Wiltshire</option>
<option value="Worcestershire">Worcestershire</option>
<option value="Yorkshire">Yorkshire</option>
</>
)

const RegionOptionsNorthernIreland=()=>(
    <>
    <option value="Antrim">Antrim</option>
<option value="Armagh">Armagh</option>
<option value="Belfast, City of">Belfast, City of</option>
<option value="Down">Down</option>
<option value="Fermanagh">Fermanagh</option>
<option value="Londonderry">Londonderry</option>
<option value="Derry, City of">Derry, City of</option>
<option value="Tyrone">Tyrone</option>
    </>
)


const RegionOptionsScotland=()=>(
    <>
    <option value="Aberdeen, City of">Aberdeen, City of</option>
<option value="Aberdeenshire">Aberdeenshire</option>
<option value="Angus">Angus (Forfarshire)</option>
<option value="Argyll">Argyll</option>
<option value="Ayrshire">Ayrshire</option>
<option value="Banffshire">Banffshire</option>
<option value="Berwickshire">Berwickshire</option>
<option value="Bute">Bute</option>
<option value="Caithness">Caithness</option>
<option value="Clackmannanshire">Clackmannanshire</option>
<option value="Cromartyshire">Cromartyshire</option>
<option value="Dumfriesshire">Dumfriesshire</option>
<option value="Dunbartonshire (Dumbarton)">Dunbartonshire (Dumbarton)</option>
<option value="Dundee, City of">Dundee, City of</option>
<option value="East Lothian">East Lothian (Haddingtonshire)</option>
<option value="Edinburgh, City of">Edinburgh, City of</option>
<option value="Fife">Fife</option>
<option value="Glasgow, City of">Glasgow, City of</option>
<option value="Inverness-shire">Inverness-shire</option>
<option value="Kincardineshire">Kincardineshire</option>
<option value="Kinross-shire">Kinross-shire</option>
<option value="Kirkcudbrightshire">Kirkcudbrightshire</option>
<option value="Lanarkshire">Lanarkshire</option>
<option value="Midlothian (County of Edinburgh)">Midlothian (County of Edinburgh)</option>
<option value="Moray (Elginshire)">Moray (Elginshire)</option>
<option value="Nairnshire">Nairnshire</option>
<option value="Orkney">Orkney</option>
<option value="Peeblesshire">Peeblesshire</option>
<option value="Perthshire">Perthshire</option>
<option value="Renfrewshire">Renfrewshire</option>
<option value="Ross and Cromarty">Ross and Cromarty</option>
<option value="Ross-shire">Ross-shire</option>
<option value="Roxburghshire">Roxburghshire</option>
<option value="Selkirkshire">Selkirkshire</option>
<option value="Shetland (Zetland)">Shetland (Zetland)</option>
<option value="Stirlingshire">Stirlingshire</option>
<option value="Sutherland">Sutherland</option>
<option value="West Lothian (Linlithgowshire)">West Lothian (Linlithgowshire)</option>
<option value="Wigtownshire">Wigtownshire</option>
    </>
)

const RegionOptionsWales=()=>(
<>
<option value="Brecon">Brecon</option>
<option value="Clwyd">Clwyd</option>
<option value="Carmarthenshire">Carmarthenshire</option>
<option value="Ceredigion">Ceredigion</option>
<option value="Denbighshire">Denbighshire</option>
<option value="Dyfed">Dyfed</option>
<option value="Flintshire">Flintshire</option>
<option value="Gwent">Gwent</option>
<option value="Gwynedd">Gwynedd</option>
<option value="Mid Glamorgan">Mid Glamorgan</option>
<option value="Pembrokeshire">Pembrokeshire</option>
<option value="Powys">Powys</option>
<option value="South Glamorgan">South Glamorgan</option>
<option value="West Glamorgan">West Glamorgan</option>

</>
)


const RegionOptionsIreland=()=>(
    <>
    <option value="">Select Region</option>
    <option value="Carlow">Carlow</option>
    <option value="Cavan">Cavan</option>
    <option value="Clare">Clare</option>
    <option value="Cork">Cork</option>
    <option value="Donegal">Donegal</option>
    <option value="Dublin">Dublin</option>
    <option value="Galway">Galway</option>
    <option value="Kerry">Kerry</option>
    <option value="Kildare">Kildare</option>
    <option value="Kilkenny">Kilkenny</option>
    <option value="Laois">Laois</option>
    <option value="Leitrim">Leitrim</option>
    <option value="Limerick">Limerick</option>
    <option value="Longford">Longford</option>
    <option value="Louth">Louth</option>
    <option value="Mayo">Mayo</option>
    <option value="Meath">Meath</option>
    <option value="Monaghan">Monaghan</option>
    <option value="Offaly">Offaly</option>
    <option value="Roscommon">Roscommon</option>
    <option value="Sligo">Sligo</option>
    <option value="Tipperary">Tipperary</option>
    <option value="Waterford">Waterford</option>
    <option value="Westmeath">Westmeath</option>
    <option value="Wexford">Wexford</option>
    <option value="Wicklow">Wicklow</option>

    </>
    )






export {SelectRegionEngland, SelectRegionNorthernIreland, SelectRegionScotland, SelectRegionWales, SelectRegionIreland}