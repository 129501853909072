
import { useHistory, useLocation } from "react-router-dom";

function useCancel(defaultPath){
    const location = useLocation();
    const history = useHistory();
    return ()=>{
        const {from} = location.state;
        if (from){
            history.replace(from);
        }
        else{
            history.replace(defaultPath);
        }
    }
}

export default useCancel;