




import messageServiceDummy from './dummy/MessageService';
import messageService from './MessageService';

import messageWithCaravanServiceDummy from './dummy/MessageWithCaravanService';
import messageWithCaravanService from './MessageWithCaravanService';




import caravanServiceDummy from './dummy/CaravanService'
import caravanService from './CaravanService'

import NotImplementedError from '_errors/NotImplementedError';

import contactUsServiceDummy from './dummy/ContactUsService';
import contactUsService from './ContactUsService'




const isDev = () => process.env.NODE_ENV === 'development';
const isDummy = () => isDev() && process.env.REACT_APP_DUMMY_DATA;




const getCaravanService = () => {
    if (isDummy()) {
        return caravanServiceDummy
    }
    else
        return caravanService;
}

const getMessageService = () => {
    if (isDummy()) {
        return messageServiceDummy
    }
    else
        return messageService;
}

const getMessageWithCaravanService = () => {
    if (isDummy()) {
        return messageWithCaravanServiceDummy
    }
    else
        return messageWithCaravanService;
}



const getContactUsService = () => {
    if (isDummy()) {
        return contactUsServiceDummy;
    }
    else
        return contactUsService;
}



const serviceFactory = {
    getContactUsService,
    getCaravanService,
    getMessageService,
    getMessageWithCaravanService
}

export default serviceFactory;