import React from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

const Hidden = ({ name}) => {
  const [field, meta] = useField({ name });
  return (
      <input type="hidden" name={name} {...field} />      
  );
};

Hidden.propTypes = {
  name: PropTypes.string.isRequired
};
export default  Hidden;
