import React from 'react';

import { List } from 'bootstrap-4-react'
import cf from 'util/caravan-functions'

const CaravanBits = ({ caravan, detailed }) => (
    <List inline className="small id_cvn_bits" id={'cvn_bits_'+caravan.uid}>
        <List.Item inline><Type caravan={caravan}/> | </List.Item>
        <List.Item inline>Length: <BoatLength caravan={caravan}/> | </List.Item>
        <List.Item inline>Bedrooms: <Bedrooms caravan={caravan}/> | </List.Item>
        <List.Item inline>Sleeps: <Sleeps caravan={caravan}/> | </List.Item>
        
        <List.Item inline>Double Beds: <DoubleBeds caravan={caravan}/> | </List.Item>
        <List.Item inline>Available: <Available caravan={caravan}/> | </List.Item>

        {detailed && <>
            <List.Item inline>Exchange Period: <ExchangePeriod caravan={caravan}/> | </List.Item>            
            {/* <List.Item inline>Caravan Features: <CaravanFeatures caravan={caravan}/> | </List.Item> */}
            {/* <List.Item inline>Site Features: <SiteFeatures caravan={caravan}/> | </List.Item> */}
        </>
        }

        <List.Item inline>Conditions: <Conditions caravan={caravan}/></List.Item>
    </List>
)
    

const Conditions = ({caravan})=>{
    const res= caravan.whatsOk && cf.convertWhatsOKListList(caravan.whatsOk).join(", ");
    return <>{res}</>
}

const CaravanFeatures = ({caravan})=>{
    const res= caravan.inside && cf.convertInsideList(caravan.inside).join(", ");
    return <>{res}</>
}

const SiteFeatures = ({caravan})=>{
    const res= caravan.outside && cf.convertOutsideList(caravan.outside).join(", ");
    return <>{res}</>
}



    const Bedrooms = ({caravan})=>{
        const bedrooms= cf.bedrooms(caravan.bedrooms);
        return <>{bedrooms}</>;
    }

    const BoatLength = ({caravan})=>{
        return <>{caravan.boatLength?caravan.boatLength:"-"}</>;
    }

    const DoubleBeds = ({caravan})=>{
        return <>{caravan.doublebeds?caravan.doublebeds:"-"}</>;
    }

    const Type = ({caravan})=>{
        const type= cf.convertType(caravan.type);
        return <>{type}</>
    }

    const Sleeps = ({caravan})=>{
        const sleeps= cf.sleeps(caravan.sleeps);
        return <>{sleeps}</>
    }

    const Available = ({caravan})=>{
        const available= cf.available(caravan.available, caravan.availableMonths);
        return <>{available}</>
    }

    const ExchangePeriod = ({caravan})=>{
        const res= caravan.period && cf.convertPeriodList(caravan.period).join(", ");
        return <>{res}</>
    }



export default CaravanBits;