import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Card, Container, Button, Badge } from 'bootstrap-4-react'

import cf from 'util/caravan-functions'

import FBImage from '_common/components/images/firebase-image'
import CaravanStats from './caravan-stats'
import CaravanBits from './caravan-bits'
import CaravanHeader from './caravan-header'
import {GoldStarIcon} from '_icons'




const CaravanDetail = ({ caravan }) => {  

  if (!caravan) return null;
  
  const cardClass = caravan.gold?'gold caravan_detail':'caravan_detail';
  return (




    <Card className={cardClass} id={'detail_'+caravan.uid}>



      <Card.Header className="shade1"><h1 id="cvn_header"><CaravanHeader caravan={caravan} /></h1></Card.Header>
      <Card.Body>
      <div style={{paddingBottom:'9px'}}>
      <CaravanBits caravan={caravan} detailed={true} />
      </div>

      <div style={{padding:'20px'}}>
          <h2 id="cvn_heading">{caravan.heading}</h2>   
          <p className="withLineBreaks" id="cvn_description">{caravan.description}</p>

          
          
     


  
          {(caravan.photos && caravan.photos.length) &&
          
         <ul className="grid" style={{marginTop:"30px"}} id="cvn_photos">
          {caravan.photos.map((photo, index)=> <li key={index}><FBImage className="img-responsive cv-detail" alt={cf.seoStr(caravan)} title={cf.seoStr(caravan)} srcPath={`/images/user/${caravan.uid}_${photo}.jpg`} /></li>
          )}
          
        </ul> 
}
         </div>
          
      
          

        </Card.Body>
      <Card.Footer className="text-right">
      {caravan.gold && <><GoldStarIcon /> Gold Member</>}
      <CaravanStats caravan={caravan} />

      </Card.Footer>
    </Card>
  )
}

export default CaravanDetail;