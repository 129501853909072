

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import ma from '_common/util/missingArg';

const userSubject = new BehaviorSubject();


const subscribeUserChange = (cb) =>
  userSubject.asObservable().subscribe(cb);

const verifyDisplayNameUnique = (displayName = ma()) => {
  return Promise.resolve();
}



//Promise<uid>
const registerWithEmailAndPassword = ({ email = ma(), password = ma(), userClaim}) => {
  return Promise.resolve(10);

}

//Promise<void>
const sendEmailVerification = (confirmedEmailSuccessUrl = ma()) => {
  return Promise.resolve();
}


//Promise<user>
const loginWithEmailAndPassword = ({ email = ma(), password = ma() }) => {
  const user =  {
    uid: password=="B"?"farmUID1":"B2"?"farmUID2":"1",
    email: "blah@blah.com",
    emailVerified: true,
    approved: true,
    displayName: "H Jones",
    type: (password=="B"||password=="B2")?"B":"A",
    photoUrl: ""    
  }
  userSubject.next(user);
  return Promise.resolve(user);
}




//Promise<void>
const logout = () => {
  userSubject.next();
  return Promise.resolve();

}




const createAuthProfile = ({ password = ma(), email = ma(), displayName = ma(), regType = ma() }) => {
  return Promise.resolve();

}


const exampleCreateFullProfile = ()=> Promise.resolve();


//Promise<void>
const updateDisplayName = (displayName = ma()) => {
  return Promise.resolve();

}



//Promise<void>
const doPasswordReset = (email = ma()) => {
  return Promise.resolve();
}


//Promise<void>
const updateEmail = (email = ma()) => {
  return Promise.resolve();

}








const userService = {
  subscribeUserChange,
  registerWithEmailAndPassword,
  sendEmailVerification,
  loginWithEmailAndPassword,
  logout,
  createAuthProfile,
  doPasswordReset,
  exampleCreateFullProfile,
  verifyDisplayNameUnique,
  updateDisplayName,
  updateEmail,
    // createAuthProfile
}

export default userService

