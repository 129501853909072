import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Card, Container, Button, Breadcrumb } from 'bootstrap-4-react'

import { AuthUserContext } from '_registration/util/session';
import * as ROUTES from '../../constants/routes';
import CaravanDetail from 'components/caravan/caravan-detail';
import Spinner from '_common/util/Spinner'

import serviceFactory from 'services/ServiceFactory';

const caravanService = serviceFactory.getCaravanService();



const MyCaravanPage = () => {

  const authUser = useContext(AuthUserContext);
  const [contact, setContact] = useState(false);
  const [caravan, setCaravan] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();


  useEffect(() => {

    let mounted = true;

    const fetchData = async () => {

      try {
        setLoading(true);
        const caravan = await caravanService.getUserCaravanDetail();

        if (mounted) {
          setCaravan(caravan);
          setLoading(false);
        }
      } catch (err) {
        if (mounted) {
          setLoading(false);
          setError(err);
        }
      }
    };
    fetchData();

    return (() => mounted = false)
  }, []);


  return (
    <>
      {loading ? <Spinner /> :

        <>

          <nav aria-label="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item><Link to={ROUTES.HOME}>Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item active aria-current="page">My Boat</Breadcrumb.Item>
            </Breadcrumb>
          </nav>


          <Container>
            <div className="p">
              {authUser && caravan.uid === authUser.uid && <Link className="btn btn-primary" role="button" to={ROUTES.MY_CARAVAN_EDIT}><i className="fa fa-pencil"></i> Edit Your Boat Advert</Link>}
            </div>


            <CaravanDetail caravan={caravan} />

          </Container>

        </>
      }
    </>
  )
}

export default MyCaravanPage
