import React, { useState, useEffect } from 'react';

import { storage } from 'util/Firebase/firebase';


const FBImage = ({ srcPath, defaultUrl, ...props }) => {

    const [url, setUrl] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        try {
            let isMounted = true; 
            setLoading(true);
            storage.ref().child(srcPath)
                .getDownloadURL().then(function (url) {
                    if (isMounted)
                        setLoading(false);
                    setUrl(url);
                    return;
                })
                .catch(function (error) {
                    if (isMounted)
                        setLoading(false);
                });

                return () => {
                    // clean up
                    isMounted = false;
                  };
        }
        catch (error) { setLoading(false); }
    }, []);

    return (
        <>
            {loading ? null : <>
                {url ? <img src={url} {...props} /> :
                    defaultUrl ? <img src={defaultUrl} {...props} /> : null}
            </>}
        </>
    )

}

export default FBImage;