import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Container, Button, Breadcrumb } from 'bootstrap-4-react'
import { c_error } from '_common/util/logger';
import { AuthUserContext } from '_registration/util/session';
import * as ROUTES from '../../constants/routes';
import CaravanDetail from 'components/caravan/caravan-detail';
import Spinner from '_common/util/Spinner'

import {LooksLikeALink} from '_common/components/CustomLink'

import MessageForm from 'components/message/message-form'
// 'components/message/message-form;'

import serviceFactory from 'services/ServiceFactory';

const caravanService = serviceFactory.getCaravanService();



const CaravanDetailPage = () => {
  const { caravanUID } = useParams();
  const history = useHistory();

  const authUser = useContext(AuthUserContext);
  const [contact, setContact] = useState(false);
  const [caravan, setCaravan] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();


  useEffect(() => {

    let mounted = true;

    const fetchData = async () => {
      
      try {
        setLoading(true);
        const caravan = await caravanService.getCaravanDetail(caravanUID);
        
        if (mounted) {
          setCaravan(caravan); 
          setLoading(false);
        }
      } catch (err) {
        c_error(err);
        if (mounted) {
          setLoading(false);
          setError(err); 
        }
      }
    };
    fetchData();

    return (() => mounted = false)
  }, []);


  return (
    <>
      {loading && <Spinner />}

{caravan && 
        <>

          <nav aria-label="breadcrumb">
          <Breadcrumb>
              <Breadcrumb.Item><LooksLikeALink onClick={()=>history.goBack()}>&laquo; Back</LooksLikeALink></Breadcrumb.Item>
              </Breadcrumb>
            {/* <Breadcrumb>
              <Breadcrumb.Item><Link to={ROUTES.HOME}>Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item><Link to={backPath}>Caravan List</Link></Breadcrumb.Item>
              <Breadcrumb.Item active aria-current="page">{caravan.heading}</Breadcrumb.Item>
            </Breadcrumb> */}
          </nav>
          <Container>

{ contact && <MessageForm toUID={caravan.uid}/>}

          <div className="p text-left">
            {(!contact && (!authUser || caravan.uid !== authUser.uid)) && <Button primary  onClick={() => setContact(true)}><i className="fa fa-envelope"></i> Contact the Owner</Button>}
            {authUser && caravan.uid === authUser.uid && <Link className="btn btn-primary" role="button" to={ROUTES.MY_CARAVAN_EDIT}><i className="fa fa-pencil"></i> Edit Your Advert</Link>}
          </div>

          
          <CaravanDetail caravan={caravan} />

        </Container>
        </>
}
      
    </>
  )
}

export default CaravanDetailPage
