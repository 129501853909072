import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';

import serviceFactory from '_registration/services/ServiceFactory'
const userService = serviceFactory.getUserService();


class LogoutPageBase extends Component {

  componentDidMount() {
    userService.logout();
  }

  render() {
    return <Redirect to="/" />;
  }

}

const LogoutPage = compose(
  withRouter
)(LogoutPageBase);

export default LogoutPage

