import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Row, Col, Lead, Container, Alert, Breadcrumb, Display4 } from 'bootstrap-4-react'

import { AuthUserContext } from '_registration/util/session';
import * as ROUTES from '../../constants/routes';
import CaravanList from 'components/caravan/caravan-list';
import Spinner from '_common/util/Spinner'
import { SadFace } from '_icons'
import serviceFactory from 'services/ServiceFactory';

const caravanService = serviceFactory.getCaravanService();

const CaravanListPage = () => {
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  const filter = searchParams.filter;


  const authUser = useContext(AuthUserContext);
  const [contact, setContact] = useState(false);
  const [caravans, setCaravans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    setLoading(false);
    let mounted = true;

    const fetchData = async () => {

      try {
        setLoading(true);
        const moreCaravans = (filter && filter === 'PHOTOS') ?
          await caravanService.getCaravanSummariesPhotosFirst({ currentPage })
          :
          await caravanService.getCaravanSummaries({ currentPage });

        if (mounted) {
          setCaravans(moreCaravans);
          setLoading(false);
        }
      } catch (err) {
        if (mounted) {
          setLoading(false);
          setError(err);
        }
      }
    };
    fetchData();

    return (() => mounted = false)
  }, [currentPage, filter]);


  return (
    <>

      <nav aria-label="breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item><Link to={ROUTES.HOME}>Home</Link></Breadcrumb.Item>
          <Breadcrumb.Item active aria-current="page">Boat List {(filter && filter === 'PHOTOS') && '(caravans with photos first)'}</Breadcrumb.Item>
        </Breadcrumb>
      </nav>

     
<h1 className="text-center">Boats available for holiday swap or exchange</h1>

      <Container id={`caravan-list_${filter ? filter : ""}`}>



        {/* {loading && <Spinner /> } */}

        {caravans.length > 0 && <CaravanList caravans={caravans} />}

        {/* <Button style={{width:'100%',marginTop:'2rem'}} primary outline onClick={() => setCurrentPage(currentPage + 1)}>More &raquo;</Button> */}

        {/* {caravans.length < 1 && <p  className="text-center display-4" style={{ marginTop: "4rem" }}>There aren't any boats registered yet <SadFace /></p>} */}
        
          <Container style={{ marginTop: "4rem" }}>
            <a href="/register" className="btn btn-primary btn-lg btn-block">Register your Boat</a>
          </Container>

          {/* {!loading && caravans.length < 1 && <Display4 className="text-center" style={{ marginTop: "4rem" }}>There aren't any boats registered yet <SadFace /></Display4>} */}

        {/* {!loading && caravans.length < 5 &&
          <Container style={{ marginTop: "4rem" }}>
            <a href="/register" className="btn btn-primary btn-lg btn-block">Register your Boat</a>
          </Container>
}  */}

          {/* {!loading && caravans.length < 5 && */}
        
          <Container className="box text-center" style={{ marginTop: "4rem", borderColor: '#054b05' }}>
            <Row>
              <Col col="col xs-auto"><img src="/images/caravan1.jpg" style={{ height: '100px' }} alt="Holiday exchange your boat with caravan owners"/></Col>
              <Col col="col xs-auto">
                <Lead>When you register your boat you can exchange with static caravans and chalets if you want. Take a look at the caravans at <a href="https://caravanexchangeclub.net" target="_blank">Caravan Exchange Club.</a></Lead>

              </Col>
              <Col col="col xs-auto"><img src="/images/caravan2.jpg" style={{ height: '100px' }} alt="Holiday exchange your boat with chalet owners"/></Col>
            </Row>
          </Container>          
          {/* } */}

    {/* {!loading && caravans.length < 2 && */}
<>
          <p className="display-4" style={{ marginTop: "4rem" }}>Example Boat Advert</p>
          <div id="detail_209_9a8a450370042308" className="card caravan_detail">
            <div className="card-header shade1"><h2 id="cvn_header">Wales - Denbighshire - Chirk Marina on Llangollen Canal</h2></div>
            <div className="card-body">


              <div style={{ paddingBottom: "9px" }}>
                <ul id="cvn_bits_209_9a8a450370042308" className=" list-inline small id_cvn_bits"><li className="list-inline-item">Narrowboat | </li>
                  <li className="list-inline-item">Length: 47ft | </li>
                  <li className="list-inline-item">Bedrooms: 2 | </li>
                  <li className="list-inline-item">Sleeps: 6 | </li>
                  <li className="list-inline-item">Double beds: 1 | </li>


                  <li className="list-inline-item">Available: June, July, September, October, April, May, July, August, September, October, March | </li><li className="list-inline-item">Exchange Period: week | </li>
                  {/* <li className="list-inline-item">Caravan Features: tv, music system, decking, bbq, dvd | </li>
                    <li className="list-inline-item">Site Features: outdoor pool, play area | </li> */}
                  <li className="list-inline-item">Conditions: Children OK, Pets OK</li>
                </ul>
              </div>
              <div style={{ padding: "20px" }}>
                <h3 id="cvn_heading">Explore the stunning Llangollen Canal</h3>
                <p className="withLineBreaks" id="cvn_description">
                  The narrowboat is fully equipped. Accommodation comprises an open plan saloon and galley at the forward end. A shower room with dump-through toilet and a cabin aft. The saloon includes a solid fuel stove which more than adequately heats the whole craft. Side hatches to each side provide light and ventilation and have clear perspex panels for the rainy and cold days.<br /><br />
                The large forward well deck provides ample space to enjoy the world slipping by away from the noise of the engine.<br />
                  <br />
There is a golf course near the marina as well as a shop and laundrette.<br />
                  <br />
We are a retired couple with small dog.</p>
                <ul className="grid" id="cvn_photos" style={{ marginTop: "30px" }}>
                  <li><img src="/images/example-boat1/img1.jpg" className="img-responsive cv-detail" alt="Boat Holiday Exchange Wales Llangollen" title="Boat Holiday Exchange Wales Chirk Marina Llangollen Canal" /></li>
                  <li><img src="/images/example-boat1/img2.jpg" className="img-responsive cv-detail" alt="Boat Holiday Exchange Wales Llangollen" title="Boat Holiday Exchange Wales Chirk Marina Llangollen Canal" /></li>
                  <li><img src="/images/example-boat1/img3.jpg" className="img-responsive cv-detail" alt="Boat Holiday Exchange Wales Llangollen" title="Boat Holiday Exchange Wales Chirk Marina Llangollen Canal" /></li>

                  <li><img src="/images/example-boat1/img4L.jpg" className="img-responsive cv-detail" alt="Boat Holiday Exchange Wales Llangollen" title="Boat Holiday Exchange Wales Chirk Marina Llangollen Canal" /></li>
                  <li><img src="/images/example-boat1/img5L.jpg" className="img-responsive cv-detail" alt="Boat Holiday Exchange Wales Llangollen" title="Boat Holiday Exchange Wales Chirk Marina Llangollen Canal" /></li>
                  <li><img src="/images/example-boat1/img6.jpg" className="img-responsive cv-detail" alt="Boat Holiday Exchange Wales Llangollen" title="Boat Holiday Exchange Wales Chirk Marina Llangollen Canal" /></li>

                </ul>
              </div>




            </div>
            <div className="card-footer text-right">

            </div>
          </div>

        </>
{/* } */}
        }






      </Container>
    </>
  )
}




export default CaravanListPage
