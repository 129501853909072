import { app, auth, db } from '../util/Firebase/firebase';

import ma from '_common/util/missingArg'

import NotLoggedOnError from '_errors/NotLoggedOnError'
import ApiError from '_errors/ApiError';

import { c_error } from '_common/util/logger';


const TABLE = "messages"



const sendMessage = ({ toUID = ma(), message = ma(), replyToID = -1 }) => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();


  return db.collection(TABLE).doc().set({
    fromUID: user.uid,
    toUID,
    message,
    replyToID,
    dateSent: app.firestore.FieldValue.serverTimestamp()
  })
    .catch(error => {
      throw (new ApiError("MessageService sendMessage", error, { fromUID: user.uid, toUID, message, replyToID }));
    });
}




const getUserInbox = () => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();

  let res = [];
  return db.collection(TABLE).where("toUID", "==", user.uid).orderBy("dateSent", "desc").get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        res.push(_fromFirestore(doc))
      });
    })
    .then(() => {
      return db.collection(TABLE).where("toUID", "==", user.uid).get().then(snapshot => {
        let batch = db.batch();
        const promises = [];
        snapshot.forEach(doc => {
          batch.update(doc.ref, { read: true });          
        });
        return batch.commit();
      })
    })
    .then(() => {
      return res;
    })
    .catch(error => {
      throw (new ApiError("MessageService getUserInbox", error, { uid: user.uid }));
    });
}


const getUserSent = () => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();

  let res = [];
  return db.collection(TABLE).where("fromUID", "==", user.uid).orderBy("dateSent", "desc").get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        res.push(_fromFirestore(doc))
      });
    })
    .then(() => {
      return res;
    })
    .catch(error => {
      throw (new ApiError("MessageService getUserSent", error, { uid: user.uid }));
    });
}




const deleteInboxMessage = ({ messageID = ma() }) => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();
  //rules set server side
  return db.collection(TABLE).doc(messageID).update({
    deletedInbox: true
  })
    .catch(error => {//ignore
      c_error(new ApiError("MessageService deleteInboxMessage", error, { uid: user.uid, messageID }));
    });
}

const deleteSentMessage = ({ messageID = ma() }) => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();
  //rules set server side
  return db.collection(TABLE).doc(messageID).update({
    deletedSent: true
  })
    .catch(error => {//ignore
      c_error(new ApiError("MessageService deleteSentMessage", error, { uid: user.uid, messageID }));
    });
}


const _fromFirestore = (doc) => {
  const data = doc.data();
  return {
    id: doc.id,
    fromUID: data.fromUID,
    toUID: data.toUID,
    message: data.message,
    dateSent: data.dateSent.toDate(),
    read: data.read,
    replied: data.replied,
    deletedInbox: data.deletedInbox,
    deletedSent: data.deletedSent,
    replyToID: data.replyToID
  }
}

const messageService = {
  sendMessage,
  getUserInbox,
  getUserSent,
  deleteInboxMessage,
  deleteSentMessage


}

export default messageService;