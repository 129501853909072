import { app, auth, db, storage } from '../util/Firebase/firebase';


import axios from 'axios'

import ma from '_common/util/missingArg'
import cf from 'util/caravan-functions';

import NotLoggedOnError from '_errors/NotLoggedOnError'
import ApiError from '_errors/ApiError';

import { MAX_PAGES, PAGE_SIZE } from 'constants/index'

import { c_log } from '_common/util/logger';

const TABLE = "boats"


let summariesStart;
let caravans = [];
let photosStart;
let caravansWithPhotos = [];
let caravanDetailStore = new Map();

let latestCaravans=[];

const getLatestCaravans =()=>{
  return Promise.resolve([]);
  // if (latestCaravans.length > 0) {
  //   c_log("******getLatestCaravans CACHED******");
  //   return Promise.resolve(latestCaravans);
  // }

  // return storage.ref().child("data/latest-boats.json")
  // .getDownloadURL().then(function (url) {
  //     return axios.get(url);
  // })
  // .then((response) => {
  //   latestCaravans = response.data;
  //   return latestCaravans;
  // })
}

const createCaravan = ({ country = ma(), county = ma(), town = ma(), heading = ma(), description = ma(),
  available = 'flexible',
  availableMonths = [],
  type = 'caravan',
  bedrooms = '',
  sleeps = '',
  whatsOk = [],
  inside = [],
  period = [],
  outside = [],
  boatLength='',
  doublebeds='' }) => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();

  let now = new Date();

  return db.collection(TABLE).doc(user.uid).set({
    country, county, town, heading,
    shortDescription: description.substring(0, description.length < 120 ? description.length : 120),
    available,
    availableMonths,
    type,
    bedrooms,
    sleeps,
    whatsOk,
    inside,
    period,
    outside,
    boatLength,
    doublebeds,
    joinDate: cf.formatDate(now),
    joinTime: now.getTime(),
    lastLogon: cf.formatDate(now),
    lastLogonTime: now.getTime(),
    status: "NOT_APPROVED"
  })
    .then(() => {
      return db.collection(TABLE).doc(user.uid).collection("details").doc("detail").set({
        description: description,
      })

    })
    .catch(error => {
      throw (new ApiError("BoatService createBoat", error,
        {
          country, county, town, heading, description,
          available,
          availableMonths,
          type,
          bedrooms,
          sleeps,
          whatsOk,
          inside,
          period,
          outside,
          boatLength,
          doublebeds
        }));
    });
}


const updateCaravan = ({ country = ma(), county = ma(), town = ma(), heading = ma(),

  description = ma(),
  available = ma(),
  availableMonths = ma(),
  type = ma(),
  bedrooms = ma(),
  sleeps = ma(),
  whatsOk = ma(),
  inside = ma(),
  period = ma(),
  outside = ma(),
  boatLength = ma(),
  doublebeds= ma() }) => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();

  const now = new Date();
  return db.collection(TABLE).doc(user.uid).update({
    country, county, town, heading,
    shortDescription: description.substring(0, description.length < 120 ? description.length : 120),
    available,
    availableMonths,
    type,
    bedrooms,
    sleeps,
    whatsOk,
    inside,
    period,
    outside,
    boatLength,
    doublebeds,
    modified: now.getTime()
  })
    .then(() => {
      return db.collection(TABLE).doc(user.uid).collection("details").doc("detail").update({
        description: description
      })

    })
    .then(() => {
      return getUserCaravanDetail()
    })
    .then((userCaravan) => {

      caravans = caravans.map((caravan) => {
        if (caravan.uid === user.uid)
          return userCaravan;
        else
          return caravan;
      })

      caravansWithPhotos = caravansWithPhotos.map((caravan) => {
        if (caravan.uid === user.uid)
          return userCaravan;
        else
          return caravan;
      })
      return;
    })
    .catch(error => {
      throw (new ApiError("BoatService updateBoat", error,
        {
          country, county, town, heading, description,
          available,
          availableMonths,
          type,
          bedrooms,
          sleeps,
          whatsOk,
          inside,
          period,
          outside,
          boatLength,
    doublebeds
        }));
    });
}



const updateLastLoginAndLastRead = () => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();

  const now = new Date();
  getUserCaravanSummary()
    .then((caravan) => {


      if (caravan.photos && caravan.photos.length > 0) {
        // let photoOrdering;//"timestamp,1,2,3" used to order photos

        // let photoOrderingList;
        // if (caravan.photoOrdering && caravan.photoOrdering.length > 0) {
        //   photoOrderingList = caravan.photoOrdering.split(",");
        //   photoOrderingList[0] = now.getTime();
        // }
        // else {
        //   photoOrderingList = [now.getTime(), ...caravan.photos];
        // }

        // photoOrdering = photoOrderingList.join();

        return db.collection(TABLE).doc(user.uid).update({
          lastLogon: cf.formatDate(now),
          lastLogonTime: now.getTime(),
          lastRead: cf.formatDate(now),
          photoOrdering: now.getTime()
        })

      }
      else {
        return db.collection(TABLE).doc(user.uid).update({
          lastLogon: cf.formatDate(now),
          lastLogonTime: now.getTime(),
          lastRead: cf.formatDate(now)
        })
      }

    })
    .catch(error => {
      throw (new ApiError("BoatService updateLastLoginAndLastRead", error));
    });


}



const getCaravanSummaries = ({ currentPage, itemsPerPage = PAGE_SIZE }) => {
  if ((currentPage > MAX_PAGES || currentPage == 1) && caravans.length > 0) {
    c_log("******CACHED******");
    return Promise.resolve(caravans);
  }


  let query = currentPage == 1 || !summariesStart || caravans.length == 0 ?
    db.collection(TABLE).where("status", "==", "").orderBy('lastLogonTime', 'desc').limit(itemsPerPage)
    : db.collection(TABLE).where("status", "==", "").orderBy('lastLogonTime', 'desc').startAfter(summariesStart).limit(itemsPerPage)


  return query.get()
    .then(function (querySnapshot) {
      summariesStart = querySnapshot.docs[querySnapshot.docs.length - 1];

      querySnapshot.forEach(function (doc) {
        caravans.push(_fromFirestoreSummary(doc))
      });

      return caravans;
    })
    .catch(error => {
      throw (new ApiError("BoatService getBoatSummaries", error));
    });
}


const getCaravanSummariesPhotosFirst = ({ currentPage, itemsPerPage = PAGE_SIZE }) => {
  if ((currentPage > MAX_PAGES || currentPage == 1) && caravansWithPhotos.length > 0) {
    c_log("******CACHED******");
    return Promise.resolve(caravansWithPhotos);
  }

  let query = currentPage == 1 || !photosStart || caravansWithPhotos.length == 0 ?
    db.collection(TABLE).where("status", "==", "").orderBy('photoOrdering', 'desc').limit(itemsPerPage)
    : db.collection(TABLE).where("status", "==", "").orderBy('photoOrdering', 'desc').startAfter(photosStart).limit(itemsPerPage)

  return query.get()
    .then(function (querySnapshot) {
      photosStart = querySnapshot.docs[querySnapshot.docs.length - 1];

      querySnapshot.forEach(function (doc) {
        caravansWithPhotos.push(_fromFirestoreSummary(doc))
      });

      return caravansWithPhotos;
    })
    .catch(error => {
      throw (new ApiError("BoatService getBoatSummariesPhotosFirst", error));
    });
}



const adminGetCaravanSummaries = ({ sinceLastGeneratedTime = ma() }) => {
  // const inactive = true;
  // if (inactive){
  //   return Promise.resolve([]);
  // }

  let result = []
  let query = !sinceLastGeneratedTime ?
    db.collection(TABLE).orderBy('lastLogonTime', 'desc').limit(10)
    :
    db.collection(TABLE).where("modified", "gt", sinceLastGeneratedTime).orderBy('lastLogonTime', 'desc').limit(10)

  return query.get()
    .then(function (querySnapshot) {
      summariesStart = querySnapshot.docs[querySnapshot.docs.length - 1];

      querySnapshot.forEach(function (doc) {
        result.push(_fromFirestoreSummary(doc))
      });

      return result;
    })
    .catch(error => {
      throw (new ApiError("BoatService getBoatSummaries", error));
    });
}


const getCaravanSummariesByListOfUID = (uidList = ma()) => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();

  if (uidList.length < 1)
    return Promise.resolve([]);

  let res = [];
  return db.collection(TABLE)
    .where(app.firestore.FieldPath.documentId(), 'in', uidList)
    .limit(10)
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        res.push(_fromFirestoreSummary(doc))
      });
      return res;
    })
    .catch(error => {
      throw (new ApiError("BoatService getBoatSummariesByListOfUID", error, { uidList }));
    });
}

const getUserCaravanSummary = () => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();

  return db.collection(TABLE).doc(user.uid).get()
    .then((doc) => {
      return _fromFirestoreSummary(doc);
    })
    .catch(error => {
      throw (new ApiError("BoatService getUserBoatSummary", error, { uid: user.uid }));
    });
}


const getCaravanDetail = (uid = ma()) => {
  return _getCaravanDetail(uid, true);
}

const getUserCaravanDetail = () => {
  const user = auth.currentUser;
  if (!user)
    throw NotLoggedOnError();

  return _getCaravanDetail(user.uid, false);
}


const _getCaravanDetail = (uid, cache) => {

  let caravan = cache ? caravanDetailStore.get(uid) : null;
  if (caravan)
    return caravan;

  return db.collection(TABLE).doc(uid).get()
    .then((doc) => {
      caravan = _fromFirestoreSummary(doc);
      return;
    })
    .then(() => {
      return _getDetailSubCollection(uid)
    })
    .then((detail) => {
      caravan.description = detail.description;
      return caravan;
    })
    .then((caravan) => {
      caravanDetailStore.set(uid, caravan);
      return caravan
    })
    .catch(error => {
      throw (new ApiError("BoatService getUserBoatDetail ", error, { uid: uid }));
    });

}

const _getDetailSubCollection = (uid) => {
  return db.collection(TABLE).doc(uid).collection('details').doc("detail").get()
    .then(function (doc) {
      return _fromFirestoreDetail(doc);
    })
}



// | Field            | Type                                                                                                                  | Null | Key | Default  | Extra |
// +------------------+-----------------------------------------------------------------------------------------------------------------------+------+-----+----------+-------+
// uid            | userID           | int(11)                                                                                                               | NO   | PRI | 0        |       |
//  ------- | title            | enum('Dr','Ms','Mrs','Mr')                                                                                            | YES  |     | NULL     |       |
// ---------| firstname        | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// ---------| lastname         | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// country  | country          | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// county   | county           | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// town     | town             | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// ---------| postcode         | varchar(10)                                                                                                           | YES  |     | NULL     |       |
// available| available        | enum('flexible','fixed')                                                                                              | YES  |     | flexible |       |
// period| period           | set('anything','weekends','week','month','season')                                                                    | YES  |     | anything |       |
// ---------| open             | enum('all year','season')                                                                                             | YES  |     | season   |       |
// ----------| seasonStartMonth | enum('Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec')                                         | YES  |     | Mar      |       |
// ---------| seasonEndMonth   | enum('Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec')                                         | YES  |     | Oct      |       |
// heading  | heading          | varchar(80)                                                                                                           | YES  |     | NULL     |       |
// type     | type             | enum('caravan','chalet','lodge','tourer')                                                                             | YES  |     | caravan  |       |
// bedrooms | bedrooms         | tinyint(4)                                                                                                            | YES  |     | NULL     |       |
// sleeps   | sleeps           | tinyint(4)                                                                                                            | YES  |     | NULL     |       |
// whatsOk  | whatsOk          | set('Children OK','Pets OK','NO smoking','NO single sex groups','Seniors only')                                       | YES  |     | NULL     |       |
// | location         | enum('in the mountains','near beach','near river','near lake','on beach','on lakeside','forest','countryside','city') | YES  |     | NULL     |       |
// inside    | inside           | set('TV','Sky','stereo','internet','decking','bbq','Freeview','dvd')                                                  | YES  |     | NULL     |       |
// outside   | outside          | set('bar','indoor pool','outdoor pool','play area','leisure','amusements')                                            | YES  |     | NULL     |       |
// description | description      | text                                                                                                                  | YES  |     | NULL     |       |
// availableMonths | availableDates   | varchar(200)                                                                                                          | YES  |     | NULL     |       |
// photos | photos           | varchar(50)                                                                                                           | YES  |     | NULL     |       |
// ----------| deletedDateTime  | datetime                                                                                                              | YES  |     | NULL     |       |
// ----------| nviews           | int(11)                                                                                                               | YES  |     | 0        |       |
// +------------

const _fromFirestoreSummary = (doc) => {
  const data = doc.data();
  let photos = data.photos ? data.photos.trim() : data.photos;
  return {
    uid: doc.id,
    heading: data.heading,
    country: data.country,
    county: data.county,
    town: data.town,
    type: data.type,
    bedrooms: data.bedrooms,
    sleeps: data.sleeps,
    available: data.available,
    availableMonths: _makeSureItsList(data.availableMonths),
    whatsOk: _makeSureItsList(data.whatsOk),
    period: _makeSureItsList(data.period),
    inside: _makeSureItsList(data.inside),
    outside: _makeSureItsList(data.outside),
    boatLength: data.boatLength,
    doublebeds: data.doublebeds,
    photos: _makeSureItsList(photos),
    shortDescription: data.shortDescription,
    gold:data.gold,
    stats: {
      lastLogon: cf.getDateFromStr(data.lastLogon),
      lastRead: cf.getDateFromStr(data.lastRead),
      nsent: data.nsent,
      nreceived: data.nreceived,
      lastSent: cf.getDateFromStr(data.lastSent),
      lastReceived: cf.getDateFromStr(data.lastReceived),
      joinDate: cf.getDateFromStr(data.joinDate),
      lastLogonTime: new Date(data.lastLogonTime)
    }
  }
}


//     +-------STATS ------+-----------------------------------------------------+------+-----+---------+-------+
// | Field           | Type                                                | Null | Key | Default | Extra |
// +-----------------+-----------------------------------------------------+------+-----+---------+-------+
// ---------------| userID          | int(11)                                             | NO   | PRI | NULL    |       |
// nsent         | nSent           | int(11)                                             | YES  |     | 0       |       |
// ----------------| nReplied        | int(11)                                             | YES  |     | 0       |       |
// nreceived     | nReceived       | int(11)                                             | YES  |     | 0       |       |
// ================| lastReplied     | date                                                | YES  |     | NULL    |       |
// lastSent      | lastSent        | date                                                | YES  |     | NULL    |       |
// lastRead      | lastRead        | date                                                | YES  |     | NULL    |       |
// lastReceived  | lastReceived    | date                                                | YES  |     | NULL    |       |
// --------| status          | enum('active','busy','inactive','suspend','notset') | YES  |     | active  |       |
// ---------| nExchanges      | int(11)                                             | YES  |     | 0       |       |
// ---------| nFeedback       | int(11)                                             | YES  |     | 0       |       |
// | deletedDateTime | datetime                                            | YES  |     | NULL    |       |
// +-----------------+-----------------------------------------------------+------+-----+---------+-------+
// 12 rows in set (0.06 sec)



const _fromFirestoreDetail = (doc) => {
  const data = doc.data();
  return {
    description: data.description
  }
}


const _makeSureItsList = (val) => {
  //old migrated stuff its a csv string not array
  //so convert to array
  let lst = val;

  if (typeof val === "string") {
    if (val.trim() === "")
      lst = []
    else
      lst = val.split(",");
  }
  return lst;
}

const caravanService = {
  getCaravanSummaries,
  getCaravanSummariesPhotosFirst,
  getCaravanDetail,
  getUserCaravanDetail,
  getCaravanSummariesByListOfUID,
  createCaravan,
  updateCaravan,
  updateLastLoginAndLastRead,
  getUserCaravanSummary,
  adminGetCaravanSummaries,
  getLatestCaravans
}

export default caravanService;