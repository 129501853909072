import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';


import { Row, Col, Card, Container, Button, Badge, Alert } from 'bootstrap-4-react'

import {CheckIcon} from "_icons";
import cf from 'util/caravan-functions'
import FBImage from '_common/components/images/firebase-image'
import { LooksLikeALink } from '_common/components/CustomLink'
import serviceFactory from 'services/ServiceFactory';


import MessageForm from './message-form'

const messageService = serviceFactory.getMessageService();

const MessageList = ({ messages, inbox, doDelete }) => {
  
    if (!messages || messages.length < 1) {
        return <Container><p className="text-center">There are no messages</p></Container>;
    }

    return (
        <Container>            
            {messages.map((messageWithCaravan, index) => <Message messageWithCaravan={messageWithCaravan} index={index} key={index} inbox={inbox} doDelete={doDelete}/>)}


        </Container>
    )

}


const Message = ({ messageWithCaravan, index, inbox, doDelete }) => {
    const [messageBox, setMessageBox] = useState(false);
    const alternatingClass = ['shade1', 'shade2'];
    const clazz = alternatingClass[index % 2];
    const otherUID = inbox ? messageWithCaravan.fromUID : messageWithCaravan.toUID;
    const sendMessageSubmitLabel = inbox ? "Send Reply" : "Send Message";
    const sendMessageMenuLabel = inbox ? "Reply" : "Send Another Message";
    const caravanLabel = inbox ? "Sent from:" : "Sent to:";
    const replyToID = inbox ? messageWithCaravan.id : -1;
    

    return (
        <Card>
            <Card.Header className={clazz}>
                <div>Sent: {cf.formatDate(messageWithCaravan.dateSent)}&nbsp;
                    {inbox && !messageWithCaravan.read && <Badge success>NEW</Badge>}
                    {(!inbox && !messageWithCaravan.read) && <Badge warning>They have not read message</Badge>}
                    {(!inbox && messageWithCaravan.read) && <><CheckIcon /> They have read your message</>}
                </div>

                <div className="text-right stacked"><Button sm primary onClick={() => setMessageBox(true)}>{sendMessageMenuLabel}</Button>  <div className="inline stacked"><LooksLikeALink onClick={() => doDelete(messageWithCaravan.id)}>Delete</LooksLikeALink></div></div>

            </Card.Header>
            <Card.Body>
                {messageBox && <NewMessageBox toUID={otherUID} submitLabel={sendMessageSubmitLabel} replyToID={replyToID}/>}
                <Row>
                    <Col col="md-8">
                       <p className="withLineBreaks"> {messageWithCaravan.message}</p>
                    </Col>

                    <Col col="md-4" className="stacked" style={{ borderLeft: '1px solid #eee' }}>
                        <div><b>{caravanLabel}</b></div>
                        <Caravan caravan={messageWithCaravan.caravan} />
                    </Col>
                </Row>
            </Card.Body>
        </Card >

    )
}






const NewMessageBox = ({ toUID, submitLabel, replyToID=-1 }) => (
    <div className="messageReplyBox" >
        <MessageForm toUID={toUID} submitLabel={submitLabel} replyToID={replyToID}/>
    </div>
)


const Caravan = ({ caravan }) => (<>
    {caravan && <> 
        <p> {caravan.heading}<br />
        {caravan.country} | {caravan.county} | {caravan.town}</p>
        {(caravan.photos && caravan.photos.length) &&
        <FBImage className="img-thumbnail cvn-summary" alt={cf.seoStr(caravan)} title={cf.seoStr(caravan)} srcPath={`/images/user/${caravan.uid}_1.jpg`} style={{ width: "200px" }} />           
        }
        <p><Link to={`${ROUTES.CARAVAN_DETAIL}/${caravan.uid}/holiday-home`}>View Details</Link></p >
    </>}
    </>
)



export default MessageList;