import React from 'react';

import { Container } from 'bootstrap-4-react'
import { ActivateAccountMessage as BaseActivateAccountMessage } from '_registration/components/email-address';

import * as ROUTES from '../../constants/routes';

const ActivateAccountMessage = () => (
    <Container>
        <BaseActivateAccountMessage successUrl={ROUTES.EMAIL_CONFIRMED_SUCCESS_URL} />
    </Container>
)
export default ActivateAccountMessage;

