import React from 'react'

import './icons.css'


const ArmStrength =({clear, ...rest}) =>  
clear? <img src="/images/icons8-muscle-64.jpg" className='icon' alt="muscle" {...rest}/>
:<img src="/images/icons8-muscle-48.jpg" className='icon'  alt="muscle" {...rest}/>

const EditIcon = (props) =>{
 return (<img className='icon-action' src="/images/edit.png"  alt="edit" {...props}/>)
}

const WarnIcon = (props) => <i className="fa fa-2x fa-exclamation-triangle red"></i>

   const FlagIcon = (props) =>{
    return (<img className="flag" src="/images/union-jack.png"  alt="GB" {...props}/>)
   }


   const QLeftIcon = (props) =>{
    return (<img className="quote" src="/images/quote-left-50.png"  alt="quote left" {...props}/>)
   }

   const QRightIcon = (props) =>{
    return (<img className="quote" src="/images/quote-right-50.png"  alt="quote right" {...props}/>)
   }


   const SadFace = ({ className, ...rest})=>{
       return  (<i className={`fa fa-frown yellow ${className}`} {...rest}></i>)
   }

   const LaughFace = (props)=><i className="fas fa-laugh orange" {...props}></i>


   const StarIcon = () => <i className="fa fa-star yellow"></i>
   
   const BoltIcon = () => <i className="fa fa-bolt yellow"></i>

   const InfoIcon = () => <i className="fa fa-info-circle blue"></i>

   const AvatarIcon = (props) => <i className="fas fa-user" {...props}></i>

   const TractorIcon = (props) => <i className="fa fa-tractor red"  {...props}></i>

   const VanIcon = ({ className, ...rest}) => <i className={`fa fa-shuttle-van orange ${className}`}  {...rest}></i>

   const CheckIcon = (props) => <i className="fa fa-check green"  {...props}></i>

   const ExclamationIcon = ({className, ...rest}) => <i className={`fa fa-exclamation orange ${className}`}  {...rest}></i>

const GoldStarIcon = ({className, ...rest}) => <i className={`fa fa-star gold ${className}`}  {...rest}></i>  

   export {ArmStrength,TractorIcon, EditIcon, WarnIcon, FlagIcon, QLeftIcon, QRightIcon, 
    LaughFace, VanIcon, SadFace, StarIcon, InfoIcon, BoltIcon, AvatarIcon, CheckIcon, ExclamationIcon, GoldStarIcon}