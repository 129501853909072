
export const IS_DEV = process.env.NODE_ENV === 'production' ? false : true;
export const CEC_EMAIL="contact@boatexchangeclub.net"
export const CEC_NOREPLY_EMAIL="noreply@boatexchangeclub.net"


export const CEC_URL = IS_DEV ? "http://localhost:3000" : "https://boatexchangeclub.net" ;

export const MAX_PAGES = 10;
export const PAGE_SIZE = IS_DEV ? 2 : 5;

export const REG_EMAIL_VERIFICATION_REQUIRED = true;
export const REG_APPROVAL_REQUIRED = true;
export const REG_APPROVAL_REQUIRED_TO_SIGN_ON = true;
export const REG_TYPE="B";