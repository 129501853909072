import React, { useState } from 'react'
import { useHistory } from "react-router-dom";

import * as Yup from 'yup';

import { TextArea, TextInput } from '_common/util/formik-bootstrap-controls'
import { Alert, Display3 } from 'bootstrap-4-react';

import * as ROUTES from 'constants/routes';
import * as CONSTANTS from 'constants/index';
import FormBase from '_form'

import serviceFactory from 'services/ServiceFactory';
const contactService = serviceFactory.getContactUsService();

const INITIAL_FORM_VALUES = {
    email: '',
    message: '',
    name: ''
};


const VALIDATION_SCHEMA = {
    email: Yup.string().email()
        .required('Required'),
    name: Yup.string(),
    message: Yup.string()
        .required('Required')
}

const ContactUsForm = () => {
    const history = useHistory();
    const [sent, setSent] = useState(false);

    const success = () => setSent(true);

    return (
        <div id="contactUsComp"> 
            <Display3>Contact Us</Display3>

            {sent ? <ContactUsFormSent />
                :
                <>
                    <p>You can send an email to {CONSTANTS.CEC_EMAIL} or use the form below</p>
                    <FormBase
                        initialData={INITIAL_FORM_VALUES}
                        validationScheme={VALIDATION_SCHEMA}
                        success={() => success()}

                       

                        saveForm={(values) => contactService.createGeneralMessage(values)}
                    >
                        {({values}) => (
                        <fieldset className="form-group">
                            <TextInput type="email" name="email" label="Email" />

                            <TextInput type="text" name="name" label="Name" />

                            <TextArea name="message" label="Message" lg />

                        </fieldset>
                        )}
                    </FormBase>
                </>
            }
        </div>

    );
}

const ContactUsFormSent = () =>
    <Alert className="text-center" success>Message sent!</Alert>

export { ContactUsForm, ContactUsFormSent }
